import { atom, selector } from "recoil";
import { LatLngExpression } from "leaflet";

interface IPopupData {
  latlng: LatLngExpression;
  properties: GeoJSON.GeoJsonProperties;
}

const popupDataAtom = atom<IPopupData | null>({
  key: 'popupDataAtom',
  default: null,
});

export const popupDataState = selector<IPopupData | null>({

  key: 'popupDataState',
  get: ({ get }) => {
    const popupData = get(popupDataAtom);
    return popupData;
  },
  set: ({ get, set }, newValue) => {
    const popupData = get(popupDataAtom);
    
    if(newValue !== popupData) {
      set(popupShowMoreState, false)
    }
    set(popupDataAtom, newValue);
  }
});

export const popupShowMoreState = atom<boolean>({
  key: 'popupShowMoreState',
  default: false,
});

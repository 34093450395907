const GeoSupportShearwaterLibraryId =
  'shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3';
const Opportunities_01Id =
  'shearwatergeonorway.sharepoint.com,3d774066-50a1-4746-ba7d-8536f3187e8d,9a1b5775-75e9-46b9-bb0c-3d3d2550a4c8';
const Opportunities_02Id =
  'shearwatergeonorway.sharepoint.com,329e9053-2ab3-4589-a334-e6e88c731128,b7d60784-ff10-4fad-8192-ae0976d01474';
const Opportunities_03Id =
  'shearwatergeonorway.sharepoint.com,fb80cba3-c93e-4db4-bf5e-c28d1cbd24c9,1d5e7ea7-6887-46b9-9c1f-e246187379ff';
const SWWebmapId = 'shearwatergeonorway.sharepoint.com,31a70ef1-741f-42fd-8eeb-29ac5ea02345,439b7b69-72bf-4a2a-a099-37f282dc9edd'

export const Libraries = {
  GeoSupportShearwater: {
    id: GeoSupportShearwaterLibraryId,
  },
  Opportunities_01: {
    id: Opportunities_01Id,
  },
  Opportunities_02: {
    id: Opportunities_02Id,
  },
  Opportunities_03: {
    id: Opportunities_03Id,
  },
  SWWebmap: {
    id: SWWebmapId,
  },
};

/**
 * How to get the library id:
 * Change the library name to the library you want to get the id for in the below code:
 *    <Get resource="/sites/shearwatergeonorway.sharepoint.com:/sites/Opportunities_02">
        <GetTemplate />
      </Get>
 */

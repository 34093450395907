import React, { useCallback } from 'react';
import { Excel } from './Excel';
import { GetDynamicLayers, IDynamicLayer } from './GetDynamicLayers';
import { KMLFile } from './KMLFile';
import { ShapefileComp } from './Shapefile';

export const DynamicLayers = () => {
  const [dynamicLayers, setDynamicLayers] = React.useState<IDynamicLayer[]>([]);

  const handleDynamicLayersUpdate = useCallback((layers: IDynamicLayer[]) => {
    setDynamicLayers(layers);
  }, []);

  const shapefiles = dynamicLayers.filter((l) => l.filetype === 'shapefile_zip');
  const excel = dynamicLayers.filter((l) => l.filetype === 'excel');
  const kmlFiles = dynamicLayers.filter((l) => l.filetype === 'kml');

  return (
    <>
      {shapefiles.map((shapefile) => (
        <ShapefileComp
          key={shapefile.filepath}
          filepath={shapefile.filepath}
          title={shapefile.title}
          colour={shapefile.layerColour}
        />
      ))}
      {excel.map((excel) => (
        <Excel key={excel.filepath} filepath={excel.filepath} title={excel.title} color={excel.layerColour} />
      ))}
      {kmlFiles.map((kmlFile) => (
        <KMLFile
          key={kmlFile.filepath}
          filepath={kmlFile.filepath}
          title={kmlFile.title}
          colour={kmlFile.layerColour}
        />
      ))}
      <GetDynamicLayers handleDynamicLayersUpdate={handleDynamicLayersUpdate} handleLoadingFinished={() => {}} />
    </>
  );
};

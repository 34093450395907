import { atom } from "recoil";
import { IProject } from "../components/Geosupport/GetProjects";
import { IListItemWithKmlUrl } from "../schemas/IListItemWithKmlUrl";
import { IOPolygon } from "../util/geosupport_utils/loadingDataHelpers";

export const projectsAtom = atom<IProject[]| undefined>({
  key: "geosupportData/projectsAtom",
  default: undefined,
});

export const surveysAtom = atom<IListItemWithKmlUrl[] | undefined>({
  key: "geosupportData/surveysAtom",
  default: undefined,
});

export const optionsAtom = atom<IListItemWithKmlUrl[] | undefined>({
  key: "geosupportData/optionsAtom",
  default: undefined,
});

export const oPolygonsAtom = atom<IOPolygon[]>({
  key: "geosupportData/oPolygonsAtom",
  default: [],
});

export const filterByYearsAtom = atom<number[]>({
  key: "geosupportData/filterByYearsAtom",
  default: [],
});

export const selectedPolygonAtom = atom<IOPolygon | undefined>({
  key: "geosupportData/selectedPolygonAtom",
  default: undefined,
});

export const allSearchableItemsAtom = atom<IListItemWithKmlUrl[]>({
  key: "geosupportData/allSearchableItems",
  default: [],
});

type IInitialGeosupportDataLoading = 
  {
    projects: boolean,
    options: boolean,
    surveys: boolean,
    decoratedProjectData: boolean,
  }


export const isLoadingInitialGeosupportDataAtom = atom<IInitialGeosupportDataLoading>({
  key: "geosupportData/isLoadingInitialGeosupportDataAtom",
  default: {
    projects: true,
    options: true,
    surveys: true,
    decoratedProjectData: true,
  },
});

// appInsights.js

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'de7c7228-568f-41ba-b517-170a08b1b10b',
    // @ts-ignore
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
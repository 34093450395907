import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useSearchSelect } from '../../hooks/geosupport/useSearchSelect';
import { IListItemWithKmlUrl } from '../../schemas/IListItemWithKmlUrl';
import { allSearchableItemsAtom, projectsAtom, selectedPolygonAtom } from '../../state/geosupportData';
import { IProject } from './GetProjects';

type IListItemWithProject = IListItemWithKmlUrl & { project?: IProject };

export const SearchDataControl = () => {
  const { handleSearchSelect } = useSearchSelect();
  const [selectedPolygon, setSelectedPolygon] = useRecoilState(selectedPolygonAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const projects = useRecoilValue(projectsAtom);
  const allSearchableItems = useRecoilValue(allSearchableItemsAtom);

  const allSearchableItemsWithProject = allSearchableItems.map((item) => {
    const project = projects?.find((p) => item.itemNumber.toLowerCase().includes(p.swNumber.toLowerCase()));
    return {
      ...item,
      project: project,
    };
  });

  const [searchItems, setSearchItems] = useState<IListItemWithProject[] | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onClose = () => {
    setSearchItems(undefined);
    setIsModalOpen(false);
    inputRef.current?.focus();
  };
  const handleSearch = (e: any) => {
    const search = e.target.value;
    const searchItems =
      search.length > 0
        ? allSearchableItemsWithProject?.filter(
            (o) =>
              o.project?.country?.toLowerCase().includes(search.toLowerCase()) ||
              o.project?.swNumber?.toLowerCase().includes(search.toLowerCase()) ||
              o.project?.client?.toLowerCase().includes(search.toLowerCase()) ||
              o.project?.title?.toLowerCase().includes(search.toLowerCase())
          )
        : undefined;
    setSearchItems(searchItems);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [isModalOpen]);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Search</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="sm" as="i">
              Please load the years you are searching for in Layers/Geosupport tab
            </Text>
            <Input
              my={2}
              placeholder="Search Geosuppport Polygons by SW Number, Client or Project Title"
              onChange={handleSearch}
              autoFocus
            />
            {searchItems &&
              searchItems.map((o) => (
                <Box key={o.itemNumber}>
                  <Link
                    onClick={() => {
                      handleSearchSelect(o.itemNumber);
                      onClose();
                    }}
                    mb={3}
                    fontSize="sm"
                  >
                    {o.project?.title} - {o.title}
                  </Link>
                </Box>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
      <InputGroup my={4}>
        <Input
          placeholder="Search by SW Number, Client or Project Title"
          onClick={() => setIsModalOpen(true)}
          ref={inputRef}
          onChange={(e) => {
            setIsModalOpen(true);
            handleSearch(e);
          }}
          value={selectedPolygon?.optionNumber ?? ''}
        />
        {selectedPolygon && (
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => {
                setSelectedPolygon(undefined);
                if (searchParams.has('optionNumber')) {
                  searchParams.delete('optionNumber');
                  setSearchParams(searchParams);
                }
              }}
            >
              Clear
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </>
  );
};

import { Box, Button, Heading, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Get, Providers } from '@microsoft/mgt-react';
import { Libraries } from '../constants/SPLibraryIds';
import { useFetchSPListData } from '../hooks/useFetchSPListData';
import { HelperLogForSharepointLibId } from './Geosupport/HelperLogForSharepointLibId';

type MSGraphListResponse = {
  value: {
    fields: {
      Title: string;
      Description: string;
      IssueType: string;
      Priority: string;
      Status: string;
      Votes: number;
      Created: string;
      Modified: string;
      id: string;
    };
  }[];
};

// Not used for now but can be later.

export const FeedbackTracker = () => {
  const { listData, fetchListData: fetchFeedbackTrackerData } = useFetchSPListData<MSGraphListResponse>({
    libraryId: Libraries['SWWebmap'].id,
    listName: 'Feedback%20Tracker',
  });

  const upVote = async (id: string, votes: number) => {
    const resource = `/sites/${Libraries['SWWebmap'].id}/lists/Feedback%20Tracker/items/${id}/fields`;

    const client = Providers.client;

    await client.api(resource).patch({
      Votes: votes + 1,
    });

    fetchFeedbackTrackerData();
  };

  console.log({ listData });

  return (
    <Box>
      <Heading>FeedbackTracker</Heading>

      {listData && (
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Text>Issue Title</Text>
              </Th>
              <Th>
                <Text>Issue Type</Text>
              </Th>
              <Th>
                <Text>Description</Text>
              </Th>
              <Th>Votes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {listData?.value.map((row) => (
              <Tr key={row.fields.id}>
                <Td>
                  <Text>{row.fields.Title}</Text>
                </Td>
                <Td>
                  <Text>{row.fields.IssueType}</Text>
                </Td>
                <Td maxW={300}>
                  <Text>{row.fields.Description}</Text>
                </Td>
                <Td>
                  <Text>{row.fields.Votes}</Text>
                  <Button
                    onClick={() => {
                      upVote(row.fields.id, row.fields.Votes);
                    }}
                  >
                    Upvote
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <HelperLogForSharepointLibId />
      {/* <GetFeedbackTrackerData handleDynamicLayersUpdate={() => {}} handleLoadingFinished={() => {}} /> */}
    </Box>
  );
};

export const GetFeedbackTrackerData: React.FC<{
  handleLoadingFinished: () => void;
  handleDynamicLayersUpdate: (layers: any[]) => void;
}> = ({ handleLoadingFinished, handleDynamicLayersUpdate }) => (
  <Get
    resource="/sites/shearwatergeonorway.sharepoint.com,31a70ef1-741f-42fd-8eeb-29ac5ea02345,439b7b69-72bf-4a2a-a099-37f282dc9edd/lists/Feedback%20Tracker/items?expand=fields"
    // resource="https://graph.microsoft.com/v1.0/sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/webmap-layers"
    dataChange={(e) => {
      handleLoadingFinished();
      const newE = e as any;
      const eValue = newE.detail.response.value as any[];
      const newLayers = eValue.map((p) => {
        const {
          Title: issueTitle,
          Description: description,
          IssueType: issueType,
          Priority: priority,
          Status: status,
          Votes: votes,
        } = p.fields;
        console.log({ issueTitle, issueType, description, priority, status, votes });
        return { issueTitle, issueType, description };

        // return { title, filetype, layerColour, filepath };
      });
      handleDynamicLayersUpdate(newLayers);
    }}
    maxPages={0}
  />
);

import L from 'leaflet';
import { LayerGroup, Marker, Popup } from 'react-leaflet';
import { useFetchFromExcel } from '../hooks/useFetchFromExcel';
import { excelDateToFormattedString } from '../util/excelDateToFormattedString';
import { isUndefinedOrSpaces } from '../util/isUndefinedOrSpaces';
import { SWLayersControlOverlay } from './SWLayersControl';

interface ExcelProps {
  filepath: string;
  title: string;
  color: string;
}

export const Excel: React.FC<ExcelProps> = ({ filepath, title, color }) => {
  const { items, isLoading } = useFetchFromExcel({ filepath });

  if (isLoading) {
    return <></>;
  }

  return (
    <SWLayersControlOverlay name={title}>
      <LayerGroup>
        {items.map((item, i) => (
          <Marker
            position={[item.LATITUDE, item.LONGITUDE]}
            key={`${item.LATITUDE}-${item.LONGITUDE}-${i}`}
            icon={L.icon({
              iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
              iconSize: [12, 20],
              iconAnchor: [6, 20],
              popupAnchor: [1, -17],
              shadowSize: [20, 20],
              shadowAnchor: [6, 20],
            })}
          >
            <Popup>
              {Object.keys(item).map(
                (key, i) =>
                  !isUndefinedOrSpaces(item[key]) && (
                    <div key={`${key} - ${i}`}>
                      <strong>{`${key}`}</strong>:{' '}
                      {`${key === 'Start' || key === 'End' ? excelDateToFormattedString(item[key]) : item[key]}`}
                    </div>
                  )
              )}
            </Popup>
          </Marker>
        ))}
      </LayerGroup>
    </SWLayersControlOverlay>
  );
};

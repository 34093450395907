export const getPolygonArrayFromKMLUrl = async (kmlUrl: string) => {
  const res = await fetch(kmlUrl);
    let reader = res?.body?.getReader();
    let result;
    let decoder = new TextDecoder('utf8');
    let kmlData: string = '';
    while (!result?.done) {
      result = await reader?.read();
      let chunk = decoder.decode(result?.value);
      kmlData = kmlData + chunk;
    }
    const kml = new DOMParser().parseFromString(kmlData, 'text/xml');
    const coordinateStrings = kml.getElementsByTagName('coordinates');

    const polygonArray: [number, number][][] = [];
    for (let i = 0; i < coordinateStrings.length; i++) {
      const coordinateStringPolygon = coordinateStrings[i].textContent;
      const coordinatesArray = coordinateStringPolygon?.split(' ');
      const coordinatesArray2 = coordinatesArray?.map((c) => c.split(',')).filter((c) => c.length >= 2);
      const coordinatesArray3 = coordinatesArray2?.map((c) => [parseFloat(c[1]), parseFloat(c[0])]) as [
        number,
        number
      ][];
      if (coordinatesArray3) {
        polygonArray.push(coordinatesArray3);
      }
    }

    return polygonArray;
};

import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

// @ts-ignore
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import { DebugObserver } from './components/DebugObserver';
import { FeedbackTracker } from './components/FeedbackTracker';

const SWTenantId = '9209ee24-f117-43b3-85d9-86cc9263c8a4';

Providers.globalProvider = new Msal2Provider({
  clientId: 'e8343b72-0a2c-4fb7-94aa-4e30cd040f93',
  scopes: ['user.read', 'openid', 'profile', 'user.readbasic.all', 'sites.read.all', 'files.read'],
  authority: `https://login.microsoftonline.com/${SWTenantId}`,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/feedback-tracker',
    element: <FeedbackTracker />,
  },
]);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <DebugObserver />
      <ChakraProvider>
        <RouterProvider router={router} />
      </ChakraProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <ChakraProvider>
//         <App />
//       </ChakraProvider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Suspense } from 'react';
import { MapContainer, Pane, TileLayer, useMap, WMSTileLayer, ScaleControl } from 'react-leaflet';
import { EdinBlocks } from './EDIN/EdinBlocks';
import { EEZBoundaries } from './EEZBoundaries';
import { Seisintel } from './Seisintel/Seisintel';
import { SeisintelVessels } from './Seisintel/SeisintelVessels';
import { SWLayersControlOverlay } from './SWLayersControl';
import { SWPopup } from './SWPopup';
import { TabbedMenu } from './TabbedMenu';

import { LeafletMeasure } from './LeafletMeasure';
import { layerNames } from '../constants/layerNames';
type MapProps = {
  children: React.ReactNode;
};

const CustomZoomPosition = () => {
  const map = useMap();
  if (!map.zoomControl) return null;

  map.zoomControl.setPosition('topright');
  return null;
};

const BasicMap: React.FC<MapProps> = ({ children }) => {
  return (
    <MapContainer center={[20, 0]} zoom={3} scrollWheelZoom={true} style={{ height: '92vh', width: '100%' }}>
      <CustomZoomPosition />
      <TabbedMenu />
      <LeafletMeasure />
      <ScaleControl position="bottomleft" metric imperial={false} />
      <SWPopup />
      <SWLayersControlOverlay checked name={layerNames.BACKGROUND_MAPBOX.name} type={layerNames.BACKGROUND_MAPBOX.type}>
        <Pane name="Background Chart from Mapbox" style={{ zIndex: 0 }}>
          <TileLayer
            attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
            url={`https://api.mapbox.com/styles/v1/gsingh1984/cldmxqadh001i01lip2ltwy0o/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3NpbmdoMTk4NCIsImEiOiJjbDFjcjV4OTIwYTloM2lvMmpwMDUzNDBqIn0.PAp60yl6IiYCVM14i5LpQQ`}
          />
        </Pane>
      </SWLayersControlOverlay>
      <SWLayersControlOverlay name={layerNames.BACKGROUND_CHART_ARCS.name} type={layerNames.BACKGROUND_CHART_ARCS.type}>
        <Pane name="ARCS Chart" style={{ zIndex: 2 }}>
          <WMSTileLayer
            layers={'0'}
            url={`https://ipw.pisysmarine.com/arcgis/services/ARCS/MapServer/WMSServer`}
            maxZoom={13}
            transparent={true}
            format="image/png"
            opacity={1}
          />
        </Pane>
      </SWLayersControlOverlay>
      <Seisintel />
      <Suspense fallback={<div>Loading...</div>}>
        <SeisintelVessels />
      </Suspense>
      <EdinBlocks />
      {/* <EEZBoundaries /> */}
      {children}
    </MapContainer>
  );
};

export default BasicMap;

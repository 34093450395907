import { Box, Spinner } from '@chakra-ui/react';

export const CenterScreenLoading: React.FC<{ inProgress: boolean }> = ({ inProgress }) => {
  return (
    <Box>
      {inProgress && (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="10000000" p={4}>
          <Spinner size={'xl'} />
        </Box>
      )}
    </Box>
  );
};

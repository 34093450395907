import { atom } from "recoil";

interface IEdinToken {
  token: string;
  expires: number;
}
export const edinTokenAtom = atom<IEdinToken | null>({
    key: "edin/edinToken",
    default: null,
});

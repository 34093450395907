import { atom, atomFamily, selector, selectorFamily } from "recoil";


export interface ILayersState {
  name: string;
  visible: boolean;
  isLoading: boolean;
  type?: string;
  edinFilter?: string;
}

export const layerIdsState = atom<string[]>({
  key: "layerIdsState",
  default: [],
});

export const layersStateFamily = atomFamily<ILayersState, string>({
  key: "LayersState",
  default: {
    name: "",
    visible: false,
    isLoading: false,
  },
});

export const layerState = selectorFamily<ILayersState, string>({
  key: "layerState",
  get: (id) => ({ get }) => {
    const layer = get(layersStateFamily(id));
    return layer;
  },
  set: (id) => ({ set }, newValue) => {
    set(layersStateFamily(id), newValue);
    set(layerIdsState, (oldLayerIds) => {
      if (!oldLayerIds.includes(id)) {
        return [...oldLayerIds, id];
      }
      return oldLayerIds;
    }
    );
  }
  
});

export const allLayersGroupedState = selector<Record<string, ILayersState[]>>({
  key: "allLayersGroupedState",
  get: ({ get }) => {
    const layerIds = get(layerIdsState);
    const layers = layerIds.map((id) => get(layerState(id)));
    const groupedLayers = layers.reduce((acc, layer) => {
      const layerType = layer.type ?? 'Others';
      if (!acc[layerType]) {
        acc[layerType] = [];
      }
      acc[layerType].push(layer);
      return acc;
    }, {} as Record<string, ILayersState[]>);

    // reorder with "Others" at the end
    const others = groupedLayers["Others"];
    if (others) {
      delete groupedLayers["Others"];
      groupedLayers["Others"] = others;
    }

    return groupedLayers;
  }
});

export const seisintelLayersState = selector<ILayersState[]>({
  key: "seisintelLayersState",
  get: ({ get }) => {
    const allLayers = get(allLayersGroupedState);
    return allLayers["Seisintel"] ?? [];
  }
});

export const geosupportLayersState = selector<ILayersState[]>({
  key: "geosupportLayersState",
  get: ({ get }) => {
    const allLayers = get(allLayersGroupedState);
    return allLayers["Geosupport"] ?? [];
  }
});

export const edinLayersState = selector<ILayersState[]>({
  key: "edinLayersState",
  get: ({ get }) => {
    const allLayers = get(allLayersGroupedState);
    return allLayers["EDIN"] ?? [];
  }
});

export const startMapLoadAtom = atom<boolean>({
  key: "startMapLoadAtom",
  default: false,
});

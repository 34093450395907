// TODO get an end point to retrieve this data from Seisintel
export const clients = 
  [
    null,
    "3D Oil",
    "A/S Norske Shell",
    "ADMA - UAE",
    "AWE Limited",
    "Abu Dhabi National Oil Company (ADNOC)",
    "Addax Petroluem (Sinopec)",
    "Afren PLC / Taleveras",
    "Aker BP ASA",
    "Alcatel Submarine Networks (ASN) / OPT",
    "Anadarko",
    "Anadarko South Africa (Pty) Ltd",
    "Apache Corporation",
    "Ardent Marine Services",
    "Argentine Ministry of Modernisation",
    "Asset Energy",
    "Australian Government / Silentworld Foundation",
    "Australian Institute of Marine Science (AIMS)",
    "Axxis Geo Solutions (AGS) Multiclient",
    "Axxis Geo Solutions (AGS) Multiclient/TGS/AkerBP",
    "AziNam",
    "BG Group",
    "BGP Multiclient / Geoex",
    "BGP Multiclient / Geoex / CUPET",
    "BHP",
    "BHP / Repsol / Hess",
    "BHP Billiton",
    "BHP Billiton / Pemex",
    "BP",
    "BP / BHP Billiton",
    "BP / Chevron",
    "BP / ExxonMobil",
    "BP / Kosmos Energy",
    "BP / Northern Endurance Partnership (NEP)",
    "BP / SOCAR",
    "BP / Shell / Siccar Point Energy",
    "BP Azerbaijan",
    "BP Berau Ltd",
    "BP Canada Energy Group ULC",
    "BP Norge AS",
    "BP Trinidad and Tobago",
    "BP Trinidad and Tobago LLC (BPTT)",
    "Bangladesh Submarine Cable Company Limited (BSCCL)",
    "Beach Energy Limited / AWE Petroleum Pty Ltd",
    "Berlanga",
    "Black Sea Oil and Gas SRL (Carlyle Group)",
    "Blue Ocean Seismic Services",
    "Brunei Shell Petroleum",
    "Brunei Shell Petroleum (BSP)",
    "CFG Energy Pte Ltd (Canadian Foresight Group)",
    "CGG / TGS",
    "CGG Multiclient",
    "CGG Multiclient / Ecopetrol",
    "CGG Multiclient / Eni / Repsol / Providence",
    "CGG Multiclient / ExxonMobil",
    "CGG Multiclient / Magseis Fairfield Multiclient",
    "CGG Multiclient / Shell / Esso E & P UK Ltd",
    "CGG Multiclient / Spectrum",
    "CGG Multiclient / TGS",
    "CGG Mutliclient",
    "CGX Energy",
    "CGX Resources Inc (CRI)",
    "CNOOC",
    "CNOOC / Chevron China Energy Company",
    "CNOOC International",
    "CONICET",
    "CPOC",
    "Cabinda Gulf Oil Company Limited (CAGBOC) (Chevron)",
    "Cairn Energy",
    "Cairn Energy (Capricorn Ireland)",
    "Cairn India",
    "Cairn Oil & Gas",
    "Caltech / UT Austin / US NSF",
    "Canadian Natural Resources International",
    "Carigali Hess Operating Company Sdn Bhd",
    "Centre for Coastal and Ocean Mapping (CCOM)",
    "Centrica Energy",
    "Chariot Oil and Gas",
    "Chevron",
    "Chevron / Eni",
    "Chevron / INPEX / Pemex",
    "Chevron / Inpex / Pemex",
    "Chevron / Sonangol / Eni / Total",
    "Chevron / Total",
    "Chevron Australia",
    "Chevron Morocco Exploration Limited",
    "Chevron Morocco Exploration Ltd",
    "Chevron North Sea Ltd",
    "Chevron Unocal",
    "China Geological Survey (CGS)",
    "Chrysaor Holdings Limited",
    "Cola Natural Resources Ghana / Medea Dev Ltd",
    "Columbia University",
    "ConocoPhillips",
    "ConocoPhillips / Petronas Carigali Sdn Bhd",
    "ConocoPhillips Australia / 3D Oil",
    "ConocoPhillips Australia Exploration Pty Ltd",
    "ConocoPhillips East Malaysia Limited (COPEM)",
    "ConocoPhillips Sarawak Oil Limited",
    "ConocoPhillips Skandinavia AS",
    "Conrad Petroleum",
    "Cooper Energy",
    "CubaPetroleo (Cupet)",
    "Cuu Long Joint Operating Company (CLJOC)",
    "DEA Mexico / Pemex / Cepsa",
    "DEA Mexico / Premier Oil / Sapura E&P",
    "DMNG / Exxon Neftegas (ENL)",
    "DNO",
    "DNO Norge AS",
    "DONG E&P Norge AS",
    "Daewoo",
    "Daewoo International",
    "Dana Gas",
    "Decipher Energy Limited",
    "Deutsche Erdoel Mexico (DEA)/ Premier Oil/ Sapura",
    "Dolphin",
    "Dolphin / TGS",
    "Dolphin Multiclient",
    "Dolphin Multiclient / Searcher Seismic",
    "Dolphin Multiclient / TGS",
    "Dragon Oil (Turkmenistan) Ltd",
    "Dubai Petroleum",
    "E.ON E&P Norge AS",
    "EGAS / PGS Multiclient",
    "EMGS",
    "EMGS Multiclient",
    "EMGS Multiclient / Corridor Resources",
    "EMGS Multiclient / Lundin",
    "EMGS Multiclient / Neptune Energy",
    "EMGS Multiclient / Repsol / PC Carigali / MedcoEnergi (Ophir)",
    "EMGS Multiclient / Spirit Energy Norway AS",
    "ENGIE E&P Norge AS",
    "EOG Resources",
    "Eastern Echo DMCC (Schlumberger Multiclient)/Total",
    "Eco (Atlantic) Oil & Gas",
    "Ecopetrol S.A.",
    "Edison",
    "Edison / Petroceltic",
    "Edison Norge AS",
    "Empyrean Energy",
    "Enarsa",
    "Energean",
    "Energean Israel Limited",
    "Energean Montenegro Limited",
    "Energie Baden-Wurttemberg AG (EnBW) / BP",
    "Energinet",
    "Energy Holdings Offshore Limited (Shell)",
    "Eni",
    "Eni / CNOOC",
    "Eni / IEOC",
    "Eni / KrisEnergy",
    "Eni / Lukoil",
    "Eni / NewAge (African Global Energy)",
    "Eni / Novatek",
    "Eni / Rosneft / BP",
    "Eni / Santos",
    "Eni Cote d'Ivoire Limited",
    "Eni Oman BV / Qatar Petroleum",
    "Eni UK Limited",
    "Eni UK Limited / Aker BP ASA / Var Energi AS",
    "Equator Exploration Ltd",
    "Equinor",
    "Equinor / Exxonmobil / Petrogal Brasil",
    "Equinor / Northern Lights JV DA",
    "Equinor / Petronic",
    "Equinor Energay AS",
    "Equinor Energy AS",
    "Equinor Energy AS / Gassnova",
    "Equinor UK Limited",
    "Equnior Energy AS",
    "Esso E & P Guyana Ltd (ExxonMobil)",
    "Esso E & P Guyana Ltd (ExxonMobil) / Hess",
    "Esso E & P Guyana Ltd (ExxonMobil) / Ratio Oil",
    "Esso E&P Guyana (ExxonMobil) / Hess / CNOOC Nexen",
    "Exxon Neftegas (ENL)",
    "Exxon Neftegas (ENL) / DMNG",
    "Exxon Neftegas Limited (ENL)",
    "ExxonMobil",
    "ExxonMobil / BP / Eni",
    "ExxonMobil / BP / Equinor / Eni",
    "ExxonMobil / Equinor / Hess",
    "ExxonMobil / GEPetrol",
    "ExxonMobil / HELLENiQ Energy",
    "ExxonMobil / Hess / Nexen",
    "ExxonMobil / QatarEnergy",
    "ExxonMobil Canada (HMDC)",
    "ExxonMobil E&P Suriname / Hess / Equinor",
    "FAR Limited / Petronas",
    "FIRST E&P / NNPC",
    "Fairfield Geotechnologies Multiclient / Anadarko",
    "Foxtrot International LDC",
    "French Navy",
    "Fugro Canada",
    "Fugro GeoSurveys / Amplified Geochemical Imaging",
    "GERG at Texas A&M University",
    "GOSCO / CGG Multiclient",
    "GX Technology Corporation (ION Geophysical)",
    "Galp Energia",
    "Gazprom",
    "Genel Energy",
    "Geoex",
    "Geoex / ION / Ghana Geophysical",
    "Geoex / Petronic",
    "Geoex MCG",
    "Geology Without Limits",
    "Geoscience Australia",
    "Geoscience Australia / JAMSTEC / IODP",
    "Global Petroleum",
    "Global Petroleum Group (GPG)",
    "Government of South Korea",
    "Great Eastern Group",
    "Gulf Energy Limited",
    "Hansa Hydrocarbons Limited",
    "Harbour Energy",
    "Hellenic Petroleum",
    "Helleniq Energy",
    "Hess / Anadarko",
    "Hess / Nexen / Unocal (Chevron) / Statoil",
    "Hess Corporation",
    "Hess Corporation / Chevron",
    "Hess Denmark ApS",
    "Hilcorp Alaska LLC (HAK)",
    "Huawei Submarine Networks / Ooredoo Maldives",
    "Husky",
    "Husky Energy",
    "Husky-CNOOC Madura Ltd (HCML)",
    "INP / Spectrum Geo / WesternGeco",
    "ION / BHP Billiton",
    "ION Geophysical",
    "ION Geophysical / Deltic Energy",
    "ION Geoventures",
    "Impact Oil & Gas",
    "InApril",
    "Inpex Browse E&P Pty Ltd",
    "Institut de Physique du Globe de Paris (IPGP)",
    "JAPEX",
    "JIP & BOEM",
    "JOGMEC / METI",
    "JX Nippon Oil & Gas Exploration (Malaysia) Limited",
    "KNOC",
    "KNOC / Woodside",
    "KPOC (Petronas / ConocoPhillips / Shell)",
    "Karmorneftegaz (ExxonMobil / Rosneft)",
    "Kosmos Energy",
    "Kosmos Energy / Galp Energia",
    "Kosmos Energy / Ophir",
    "Kosmos Energy / Trident Energy / Tullow Oil",
    "KrisEnergy",
    "Larus Energy",
    "Lukoil",
    "Lundin",
    "Lundin Energy Norway AS",
    "Lundin Malaysia",
    "Lundin Norway AS",
    "Lundin Petroleum",
    "MNRE of the Russian Federation",
    "Maersk Oil / Danish Underground Consortium (DUC)",
    "Maersk Oil North Sea Uk Ltd",
    "Maersk Oil Qatar",
    "Magseis ASA (Multiclient)",
    "Malaysian Government",
    "Masirah Oil Ltd",
    "Maurel & Prom Namibia",
    "Medco E&P Natuna Ltd",
    "Medco Energy",
    "Ministry of Energy and Mineral Resources",
    "Ministry of Energy and Mineral Resources (MEMR)",
    "Ministry of Foreign Affairs Sultanate of Oman",
    "Mitra Energy",
    "Mubadala",
    "MultiClient Geophysical AS / Seismic Partner",
    "Multiclient Geophysical (MCG)",
    "Multiclient Geophysical AS",
    "Multiclient Geophysical ASA",
    "Murphy Oil",
    "Murphy Oil Sarawak Ltd",
    "NMT / OSU / US National Science Foundation (NSF)",
    "NSCO Investments Limited",
    "NSF",
    "NSF / Woods Hole Oceanographic Institution (WHOI)",
    "NTNU / PGS",
    "Nabirm",
    "Naftogaz",
    "National Science Foundation (NSF USA)",
    "National Science Foundation (NSF)",
    "National Science Foundation (NSF) / OCE / MGG",
    "Nederlandse Aardolie Maatschappij (NAM)",
    "Neptune Energy Norge AS",
    "New Age (African Global Energy)",
    "Nexen",
    "Nexen Petroleum",
    "Nexen Petroleum U.K. Limited",
    "Nexen Petroluem U.K. Ltd",
    "Noble Energy",
    "Noreco Norway AS",
    "North Oil Company (NOC) (Qatar Petroleum / Total)",
    "North Sea Natural Resources (NSNRL)",
    "North Sinai Petroleum Company (NOSPCO)",
    "Norwegian Ministry of Petroleum and Energy",
    "Norwegian Petroleum Directorate (NPD)",
    "Nuclear Waste Services",
    "OKEA ASA",
    "OMV",
    "OMV (Norge) AS",
    "OMV New Zealand Ltd",
    "ONGC",
    "ONGC / Essar",
    "ONGC Videsh and Oil India",
    "ONHYM",
    "Oil India Ltd (OIL)",
    "Oil and Gas Authority UK",
    "Ophir",
    "Ophir Energy",
    "Oranto Petroleum",
    "Origin Energy",
    "Oryx Petroleum",
    "PEXCO Sarawak N.V",
    "PGN Saka",
    "PGNiG Upstream Norway AS",
    "PGS / TGS",
    "PGS Multiclient",
    "PGS Multiclient / Chevron / Woodside",
    "PGS Multiclient / EGAS",
    "PGS Multiclient / EnQuest / Cairn",
    "PGS Multiclient / Eni",
    "PGS Multiclient / Eni / Equinor / Sonangol",
    "PGS Multiclient / Eni / Sasol Africa Limited",
    "PGS Multiclient / Equinor",
    "PGS Multiclient / Equinor / Jersey Oil and Gas",
    "PGS Multiclient / ExxonMobil / Impact / SilverWave",
    "PGS Multiclient / ExxonMobil / NAMCOR",
    "PGS Multiclient / ExxonMobil / Qatar Petroleum",
    "PGS Multiclient / ExxonMobil E & P Ghana (Deepwater) Ltd / GNPC",
    "PGS Multiclient / MIGAS",
    "PGS Multiclient / Multiclient Geophysical",
    "PGS Multiclient / Murphy",
    "PGS Multiclient / Premier Oil",
    "PGS Multiclient / SNPC",
    "PGS Multiclient / Sonangol",
    "PGS Multiclient / Spectrum",
    "PGS Multiclient / Statoil",
    "PGS Multiclient / TGS",
    "PGS Multiclient /Chevron /Shell /Noble/ Total",
    "PGS Multiclient/ExxonMobil/Oil Search/Gini Energy",
    "PGS Multiclient/OKEA/ConocoPhillips Skandinavia",
    "PNOC",
    "PT Elnusa",
    "PT Pertamina EP",
    "PT Pertamina Hulu Energi (PHE)",
    "PT. Saka Energi Yamdena / SKKMIGAS",
    "PTTEP",
    "PTTEP HKO",
    "PTTEP South Asia Ltd",
    "Pancontinental / Woodside",
    "Pemex",
    "Perenco",
    "Pertamina",
    "Pertamina Hulu Energi",
    "Pertamina Hulu Energi (PHE)",
    "Pertamina Hulu Energi Lepas Pantai Bunyu (PHE-LPB)",
    "Petrica Energy / AGM Petroleum",
    "Petrobras",
    "Petrobras / BP / Galp",
    "Petrobras / Shell / Repsol",
    "Petrobras Tanzania",
    "Petrogas E&P Netherlands (PEPN)",
    "Petromarker Multiclient",
    "Petronas",
    "Petronas / PGS Multiclient",
    "Petronas / ROC Oil",
    "Petronas / Staatsolie",
    "Petronas Carigali",
    "Petronas Carigali / Hess",
    "Petronas Carigali Myanmar",
    "Petronas Carigali Sdn Bhd",
    "Petronas Carigali Sdn. Bhd",
    "Petronas Suriname E&P B.V. / ExxonMobil",
    "Pharos Energy",
    "Philex",
    "Philia SA",
    "Philippines DoE",
    "Point Resources AS",
    "Polarcus Multiclient",
    "Polarcus Multiclient / Erin Energy",
    "Polarcus Multiclient / ION GeoVentures",
    "Polarcus Multiclient / Petrobras",
    "Polarcus Multiclient / Spectrum / DUG",
    "Polarcus Multiclient / Woodside",
    "Posco Daewoo",
    "Premier Oil",
    "Premier Oil Andaman Limited / BP / Mubadala Energy",
    "Primeline Energy Holdings Inc / CNOOC",
    "Pusat Survei Geologi",
    "Pusat Survei Geologi (PSG)",
    "Quadrant Energy",
    "Queiroz Galvão E & P (QGEP)",
    "RAK Gas",
    "Reliance Industries Limited / BP",
    "Repsol",
    "Repsol / ExxonMobil / Statoil",
    "Repsol / Petronas / PetroVietnam",
    "Repsol / Tullow / TotalEnergies",
    "Repsol / Tullow Oil / Eco Atlantic (Guyana) Inc",
    "Repsol Exploration Australia",
    "Rosgeologia / DMNG",
    "Rosneft",
    "Rosneft / ExxonMobil",
    "Rosneft / Statoil",
    "Rutgers University",
    "SEARCH Inc",
    "SHA Angola",
    "SK Innovation Co., Ltd",
    "SK innovation",
    "SKK Migas / PT Pertamina (Persero) Group",
    "SOCO Exploration Vietnam",
    "Sabah Shell Petroleum Company",
    "Sakhalin Energy Investment Company (SEIC) / Shell",
    "Sakhalin Energy Investment Company Ltd (SEIC)",
    "Santos",
    "Santos / Carnarvon Petroleum Limited",
    "Santos / KrisEnergy",
    "Santos / Magellan Petroleum",
    "Santos / Total / Melbana Energy Limited",
    "SapuraOMV",
    "Sarawak Shell Berhad",
    "Saudi Aramco",
    "Schlumberger",
    "Scripps Institution of Oceanography (SIO)",
    "Seabird / GeoPartners",
    "Seabird / Lundin Norway",
    "Seabird / Multiclient Geophysical (MCG)",
    "Seabird Multiclient",
    "Seabird Multiclient / GeoPartners / DUG",
    "Seabird Multiclient / Polarcus / GeoPartners / ION",
    "Searcher Seismic",
    "Searcher Seismic / BW Energy",
    "Searcher Seismic / MAGE",
    "Searcher Seismic / Shearwater",
    "Secretaria Tecnica del Mar Ecquador (SETEMAR)",
    "Shell",
    "Shell (A/S Norske Shell)",
    "Shell / BG Group",
    "Shell / BP",
    "Shell / BP / Eni",
    "Shell / CNOOC",
    "Shell / Chevron",
    "Shell / Cluff Natural Resources / Deltic Energy",
    "Shell / ConocoPhillips / Petronas / Murphy",
    "Shell / ConocoPhillips / Petronas Carigali",
    "Shell / Equinor",
    "Shell / Impact Oil and Gas",
    "Shell / MDC / SKE",
    "Shell / Noble",
    "Shell / Petronas",
    "Shell / Total",
    "Shell Australia Pty Ltd",
    "Shell Brunei Petroleum (BSP)",
    "Shell Malaysia",
    "Shell Myanmar Energy PTE Ltd",
    "Shell Myanmar Energy PTE Ltd / Statoil",
    "Shell Nigeria Exploration and Production Company",
    "Shell Taranaki Limited",
    "Shell Todd",
    "Shell UK Ltd / Esso E&P UK Ltd (ExxonMobil)",
    "Sonangol",
    "Sovcomflot (OOO SCF Geo) / Rosneft",
    "Spectrum / WesternGeco",
    "Spectrum ASA",
    "Spectrum Geo",
    "Spectrum Geo / BGP",
    "Spectrum Geo / DGH",
    "Spectrum Geo / TGS",
    "Spectrum Geo / YPF / Statoil",
    "Spirit Energy Norway AS",
    "Springfield E and P Company / GNPC",
    "Sri Lanka PRDS",
    "Staatsolie / CGG Multiclient / TGS",
    "Statoil",
    "Statoil / Petoro AS / Total / ConocoPhillips",
    "Statoil ASA",
    "Statoil Canada Limited",
    "Statoil Petroleum AS",
    "Statoil Petroleum AS /Neptune Energy /OMV /Repsol",
    "Sterling Energy (UK) Limited",
    "Sungu Sungu Namibia Pty Ltd",
    "Svenska Petroleum Exploration",
    "TGS",
    "TGS (Nopec Geophysical) / ExxonMobil / Tullow",
    "TGS (Nopec Geophysical) / Tullow",
    "TGS / BGP",
    "TGS / BGP / CGG Multiclient / Capricorn Energy PLC",
    "TGS / CNOOC / Repsol",
    "TGS / Equinor / YPF",
    "TGS / GeoPartners",
    "TGS / GeoPartners / Petrosen",
    "TGS / Norwegian Petroleum Directorate",
    "TGS / OMV",
    "TGS / PGS",
    "TGS / PGS / Geopartners",
    "TGS / PGS / Geopartners / Petrosen / MPE Gambia",
    "TGS / PGS Multiclient",
    "TGS / PGS Multiclient / Nalcor Energy",
    "TGS / PGS Multiclient / WesternGeco",
    "TGS / PetroData",
    "TGS / SLB / PETROBANGLA",
    "TGS / Schlumberger",
    "TGS / WesternGeco",
    "TGS / WesternGeco / Ganope",
    "TGS / WesternGeco Multiclient",
    "TIMOR GAP",
    "Talisman Andaman BV (Repsol)",
    "Techwin Energy",
    "The CarbonNet Project",
    "The GoNorth Consortium / Ampseis",
    "Todd Energy Limited",
    "Todd Exploration",
    "Total",
    "Total / Apache Corporation",
    "Total / BP / Pan America Energy",
    "Total / CNOOC / CPC",
    "Total / Chevron",
    "Total / Chevron / PTTEP",
    "Total / Creocean",
    "Total / Eni",
    "Total / ExxonMobil",
    "Total / Noreco",
    "Total / Pemex",
    "Total / Qatar Petroleum",
    "Total / Qatar Petroleum / CNR International",
    "Total / Tullow",
    "Total / Tullow Oil / BP / Kosmos Energy",
    "Total E & P Angola",
    "Total E & P Angola (TEPA)",
    "Total E & P UK",
    "Total E&P Bulgaria B.V.",
    "Total E&P Congo",
    "Total E&P Malaysia",
    "Total E&P Myanmar",
    "Total E&P Nigeria",
    "Total E&P Nigeria / CNOOC",
    "Total E&P Senegal",
    "Total E&P UK",
    "Total Gabon",
    "Total Upstream Nigeria Limited (​TUPNI)",
    "TotalEnergies",
    "TotalEnergies / BP / JX Nippon",
    "TotalEnergies EP Danmark AS",
    "Trace Atlantic Oil Ltd (TAOL)",
    "Trident",
    "Tulip Oil (TONO)",
    "Tullow Oil",
    "Tullow Oil (Ghana) Ltd",
    "Tullow Oil / Discover Exploration Ltd",
    "Tullow Oil / Kosmos Energy / Anadarko",
    "Tullow Oil / United Oil and Gas",
    "Tullow Oil Norge AS",
    "Turkish Energy and Natural Resources",
    "Turkish Petroleum (TPAO)",
    "U.S. National Science Foundation (NSF)",
    "UK Oil & Gas Authority",
    "US NSF",
    "US-NSF / NERC",
    "United States Geological Survey (USGS)",
    "University of California / Woods Hole Oceanographic Institution",
    "University of Sydney / University of Tasmania",
    "VAALCO Energy",
    "Var Energi AS",
    "Vietsovpetro",
    "Vineyard Offshore Wind",
    "Wellesley Petroleum AS",
    "WesternGeco Multiclient",
    "WesternGeco Multiclient / Chevron / Statoil",
    "WesternGeco Multiclient / Neptune Energy",
    "WesternGeco Multiclient / OMV",
    "WesternGeco Multiclient / PGS Multiclient / TGS",
    "WesternGeco Multiclient / TGS",
    "WesternGeco Multiclient / Total / Shell",
    "WesternGeco Mutliclient",
    "Wintershall Norge AS",
    "Wintershall Norge AS/Equinor Energy AS/OMV Norge",
    "Woodside",
    "Woodside / FAR Limited / Cairn Energy",
    "Woodside Energy",
    "Woodside Energy (Ireland) Pty Ltd"
  ]

  export const companies = [
    null,
    "ANRE Japan",
    "Allianz Middle East",
    "Amar Shipping",
    "Argeo",
    "Atlantic Offshore",
    "Atlantic Towing",
    "Australian Institute of Marine Science (AIMS)",
    "Axxis Geo Solutions (AGS)",
    "BGP",
    "Bordelon Marine",
    "Bourbon Offshore",
    "CGG",
    "CONICET Argentina",
    "COSL",
    "CSIRO",
    "Canadian Coast Guard",
    "Carmet Tug Co",
    "Caspian Geophysical",
    "Caspian Marine Services",
    "China Geological Survey (CGS)",
    "Columbia University",
    "ConocoPhillips",
    "DMNG",
    "DOF",
    "DOF Subsea",
    "Delta Logistics",
    "Dolphin Geophysical",
    "EMGS",
    "Edison Chouest Offshore",
    "Eidesvik",
    "Elnusa",
    "Fairfield Geotechnologies",
    "Fast Offshore",
    "Fugro",
    "GC Rieber",
    "Gardline",
    "Glomar Ship Management BV",
    "Guangzhou Marine Geological Survey",
    "Guardian Geomatics",
    "GulfMark",
    "Havila Shipping ASA",
    "Hind Offshore",
    "Holyhead Towing",
    "Horizon Geosciences",
    "Institute of Marine Research (IMR) Norway",
    "Irish Mainport",
    "Island Offshore",
    "JAMSTEC",
    "JD-Contractor AS",
    "Limin Marine",
    "MAGE",
    "MMA Offshore",
    "MMT",
    "MTA Turkey",
    "Maersk Supply Service",
    "Magseis",
    "Magseis Fairfield",
    "Mahakarya Geo Survey (MGS)",
    "Maritim Management",
    "Miclyn Express Offshore (MEO)",
    "National Science Foundation (NSF)",
    "Nordic Maritime",
    "Northern Survey",
    "O H Meling and Co AS",
    "Ocean Geo-Frontier co. Ltd (OGF)",
    "Ocean Infinity",
    "OceanGeo (ION)",
    "Olympic Subsea ASA",
    "Otto Candies",
    "PACC Offshore Services Holdings (POSH)",
    "PGS Exploration",
    "POSH",
    "PT Triton Laut Biru",
    "PT Wintermar",
    "PXGEO",
    "Pacific Marine Group",
    "Pacific Radiance",
    "Polar Marine Geosurvey Expedition",
    "Polarcus",
    "REM Offshore",
    "Reach Subsea",
    "SAExploration",
    "SCF GEO",
    "SMNG",
    "Sanco Shipping",
    "Seabed Geosolutions",
    "Seabird",
    "Shearwater",
    "Siem Offshore AS",
    "Sinopec",
    "Solstad Offshore ASA",
    "SolstadFarstad",
    "Stanford Marine",
    "Sun Atlantic LTD",
    "Swiber",
    "Swire Pacific Offshore",
    "TDI Brooks",
    "Thor Ltd",
    "Tidewater",
    "Turkisk Petroleum Corp",
    "Unknown",
    "Vestland",
    "Volstad",
    "Vroon Offshore",
    "WesternGeco"
  ]

  export const countries = [
    "Algeria",
    "Angola",
    "Argentina",
    "Aruba",
    "Australia",
    "Azerbaijan",
    "Bangladesh",
    "Barbados",
    "Belize",
    "Brazil",
    "Brunei Darussalam",
    "Bulgaria",
    "Cambodia",
    "Canada",
    "Cayman Islands",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Cuba",
    "Cyprus",
    "Côte d'Ivoire",
    "Denmark",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "Equatorial Guinea",
    "Faroe Islands",
    "France",
    "French Guiana",
    "French Polynesia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Greece",
    "Greenland",
    "Grenada",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Honduras",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Ireland",
    "Israel",
    "Jamaica",
    "Japan",
    "Kenya",
    "Korea, Republic of",
    "Libya",
    "Madagascar",
    "Malaysia",
    "Maldives",
    "Malta",
    "Mauritania",
    "Mexico",
    "Micronesia, Federated States of",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Panama",
    "Papua New Guinea",
    "Peru",
    "Philippines",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "Spain",
    "Sri Lanka",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Taiwan, Province of China",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Venezuela, Bolivarian Republic of",
    "Viet Nam",
    "Western Sahara"
  ]

  export const surveyTypes = [
    "2D",
    "3D",
    "3D FAZ",
    "3D MAZ",
    "3D P-Cable",
    "3D WAZ",
    "4C",
    "4D",
    "4D WAZ",
    "EBS",
    "EM",
    "Enviro",
    "Field Test",
    "GEO",
    "Geo",
    "Multibeam",
    "OBC",
    "OBN",
    "Site",
    "Undershoot",
    "Unknown",
    "VSP"
  ]

  export const vessels = [
    null,
    "Adira",
    "Akademik Fersman",
    "Akademik Lazarev",
    "Akademik Nametkin",
    "Akademik Nemchinov",
    "Akademik Primakov",
    "Akademik Shatskiy",
    "Alima",
    "Alize",
    "Allianz Titanium",
    "Amadeus",
    "Amani",
    "Amazon Conqueror",
    "Amazon Warrior",
    "Aquila Explorer",
    "Argeo Searcher",
    "Artemis Angler",
    "Artemis Arctic",
    "Artemis Athene",
    "Artemis Atlantic",
    "Artemis Odyssey",
    "Asima",
    "Assister",
    "Athba",
    "Atlantic Eagle",
    "Atlantic Explorer",
    "Atlantic Guardian",
    "Austral",
    "BGP Challenger",
    "BGP Commander",
    "BGP Explorer",
    "BGP Glory",
    "BGP Innovator",
    "BGP Pioneer",
    "BGP Prospector",
    "BOA Thalassa",
    "Barbaros Hayreddin Pasa",
    "Bin Hai 517",
    "Bin Hai 527",
    "Binh Minh 2",
    "Bongo",
    "Bourbon Evolution 802",
    "Brooks McCall",
    "CT Vector",
    "Carolyn Chouest",
    "Carrier Express",
    "Cecilia",
    "Chloe Candies",
    "Crest Alpha 1",
    "Crest Argus 2",
    "Crest Argus 3",
    "Crest Radiant 5",
    "Crest Spartan 3",
    "Crest Spartan 8",
    "Delta Monarch",
    "Discoverer",
    "Discoverer 2",
    "Dong Fang Kan Tan No. 1",
    "Dong Fang Kan Tan No. 2",
    "Dong Fang Ming Zhu",
    "Dove",
    "Duke",
    "Eagle Explorer",
    "Eland",
    "Elsa Regent",
    "European Supporter",
    "FOS Orion",
    "FOS Virgo",
    "Fa Xian 6",
    "Fairfield Challenger",
    "Fugro Americas",
    "Fugro Brasilis",
    "Fugro Discovery",
    "Fugro Equator",
    "Fugro Galaxy",
    "Fugro Gauss",
    "Fugro Meridian",
    "Fugro Pioneer",
    "Fugro Searcher",
    "Fugro Supporter",
    "Fugro Venturer",
    "Fulmar Explorer",
    "Geco Diamond",
    "Geco Eagle",
    "Geco Emerald",
    "Geco Topaz",
    "Geo Arctic",
    "Geo Caribbean",
    "Geo Caspian",
    "Geo Celtic",
    "Geo Coral",
    "Geo Hindsagar",
    "Geo Pacific",
    "Geoholm",
    "Geosund",
    "Geowave Commander",
    "Geowave Voyager",
    "Gilavar",
    "Glomar Worker",
    "Grand Canyon II",
    "Guba",
    "Guru",
    "Gyre",
    "HAI BAO 2",
    "Hai Bao 4",
    "Hai Bao Jiu (HB9)",
    "Hai Bao Liu Hoa (HB6)",
    "Hai Bao Qi (HB7)",
    "Hai Bao Wu Hao",
    "Hai Yang Di Zhi Ba Hao",
    "Hai Yang Di Zhi Jiu Hao",
    "Hai Yang Di Zhi Shi Hao",
    "Hai Yang Shi You 718",
    "Hai Yang Shi You 719 (HYSY 719)",
    "Hai Yang Shi You 720 (HYSY 720)",
    "Hai Yang Shi You 721 (HYSY 721)",
    "Hai Yang Shi You 751 (HYSY 751)",
    "Hai Yang Shi You 760 (HYSY 760)",
    "Hailey Sarah",
    "Harrier Explorer",
    "Harvey Deep Sea",
    "Havila Aurora",
    "Havila Charisma",
    "Havila Fortune",
    "Havila Harmony",
    "Havila Subsea",
    "Hawk Explorer",
    "Horizon Geobay",
    "Hugin Explorer",
    "Investigator",
    "Island Enforcer",
    "Island Pride",
    "Island Vanguard",
    "Ivan Gubkin",
    "John P Tully",
    "Kaimei",
    "Kairei",
    "Karina 68",
    "Kobi Ruegg",
    "Kommander",
    "Kronprins Haakon",
    "MAC Pegasus",
    "MAC Phoenix",
    "MMA Monarch",
    "MMA Valour",
    "MMA Vigilant",
    "MTA Oruc Reis",
    "MV Sigma",
    "Maersk Norseman",
    "Mainport Cedar",
    "Mar Fortune",
    "Marcus G. Langseth",
    "Maris",
    "Marshal Vasilevskiy",
    "Meo Sovereign 2",
    "Munin Explorer",
    "NOR Australis",
    "Naila",
    "Nalivkin",
    "Neptune",
    "Neptune Naiad",
    "Nikolay Trubyatchinskiy",
    "Nordic Bahari",
    "Nordic Explorer",
    "Normand Frontier",
    "Normand Mermaid",
    "Normand Prosper",
    "Normand Tonjer",
    "North Barents",
    "Northern Explorer",
    "Ocean Duke",
    "Ocean Endeavour",
    "Ocean Europe",
    "Ocean Marlin",
    "Ocean Mermaid",
    "Ocean Pearl",
    "Ocean Reliance",
    "Ocean Resolution",
    "Ocean Sea",
    "Oceanic Challenger",
    "Oceanic Champion",
    "Oceanic Endeavour",
    "Oceanic Phoenix",
    "Oceanic Sirius",
    "Oceanic Vega",
    "Oceanus",
    "Olympic Artemis",
    "Olympic Challenger",
    "Olympic Delta",
    "Olympic Taurus",
    "Onyx",
    "Orient Explorer",
    "Osprey Explorer",
    "PGS Apollo",
    "PMG Pride",
    "POSH Deep C",
    "PXGEO 2",
    "Pacific Finder",
    "Pacific Harrier",
    "Petrel Explorer",
    "Polar Duchess",
    "Polar Duke",
    "Polar Empress",
    "Polar Marquis",
    "Polaris",
    "Posh Defender",
    "Professor Kurentsov",
    "Professor Logachev",
    "Professor Rjabinkin",
    "Puffin Island",
    "R/V Proteus",
    "REM Andes",
    "REM Aquarius",
    "REM Saltire",
    "Ramform Atlas",
    "Ramform Challenger",
    "Ramform Explorer",
    "Ramform Hyperion",
    "Ramform Sovereign",
    "Ramform Sterling",
    "Ramform Tethys",
    "Ramform Titan",
    "Ramform Valiant",
    "Ramform Vanguard",
    "Ramform Victory",
    "Ramform Viking",
    "Regent",
    "Regulus",
    "Relume",
    "SW Amundsen",
    "SW Baret",
    "SW Bly",
    "SW Columbus",
    "SW Cook",
    "SW Diamond",
    "SW Duchess",
    "SW Emerald",
    "SW Empress",
    "SW Gallien",
    "SW Magellan",
    "SW Mikkelsen",
    "SW Tasman",
    "SW Thuridur",
    "SW Vespucci",
    "Sanco Atlantic",
    "Sanco Spirit",
    "Sanco Star",
    "Sanco Swift",
    "Sanco Sword",
    "Sea Surveyor",
    "Seabed Constructor",
    "Seabed Supporter",
    "Seabulk Advantage",
    "Shelia Bordelon",
    "Shigen",
    "Siddis Mariner",
    "Siddis Sailor",
    "Siem Dorado",
    "Siem Pride",
    "Skandi Hugen",
    "Skandi Neptune",
    "Skandi Nova",
    "Solander",
    "Stanford Bateleur",
    "Stanford Buzzard",
    "Stanford Hawk",
    "Stanford Provider",
    "Subsea Viking",
    "Surf Allamanda",
    "Swiber Challenger",
    "Swiber Ruby",
    "Swissco Neptune",
    "Tansa",
    "Thor Omega",
    "Triton 501",
    "VOS Star",
    "Vantage",
    "Venturer",
    "Veritas Viking",
    "Viking Vanquish",
    "Viking Vision",
    "Voyager Explorer",
    "Vyacheslav Tikhonov",
    "WG Amundsen",
    "WG Columbus",
    "WG Cook",
    "WG Magellan",
    "WG Tasman",
    "WG Vespucci",
    "WM Natuna",
    "Western Patriot",
    "Western Spirit",
    "Western Trident",
    "Zephyr-1"
  ]

  export const surveyStatus = [
    "In Progress",
    "Completed",
  ]

  // all years starting from 2014 till current year

  const currentYear = new Date().getFullYear();


  export const productionYear = Array.from({length: currentYear - 2013 }, (v, k) => k + 2014).reverse();





// @ts-ignore
import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { addRulerControl } from '../leaflet-measure/leaflet-ruler';

export const LeafletMeasure = () => {
  const map = useMap();

  const firstLoad = useRef(true);

  // add measure control to map

  useEffect(() => {
    if (!firstLoad.current) {
      return;
    }
    addRulerControl(map);
    firstLoad.current = false;
  }, [map]);
  return null;
};

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { optionsAtom, projectsAtom, selectedPolygonAtom, surveysAtom } from '../state/geosupportData';
import { showSideDrawerViewDetails } from '../state/sideDrawerViewDetails';
import { getOption } from '../util/geosupport_utils/getOption';
import { getProject } from '../util/geosupport_utils/getProject';
import { getSurvey } from '../util/geosupport_utils/getSurvey';

export const SideDrawer: React.FC = () => {
  const selectedPolygon = useRecoilValue(selectedPolygonAtom);
  const projects = useRecoilValue(projectsAtom);
  const options = useRecoilValue(optionsAtom);
  const surveys = useRecoilValue(surveysAtom);
  const [showViewDetails, setShowViewDetails] = useRecoilState(showSideDrawerViewDetails);
  const onClose = () => setShowViewDetails(false);

  if (!selectedPolygon) return <></>;

  const project = getProject(selectedPolygon?.optionNumber, projects);
  const option = getOption(selectedPolygon?.optionNumber, options) || getSurvey(selectedPolygon?.optionNumber, surveys);

  return (
    <>
      <Drawer isOpen={showViewDetails} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Details</DrawerHeader>

          <DrawerBody>
            <Link
              href={`https://apps.powerapps.com/play/2bebf645-d4dc-48a2-80c0-4d60cc512deb?tenantId=9209ee24-f117-43b3-85d9-86cc9263c8a4&source=portal&Shearwater_Number=${project?.swNumber}`}
              isExternal
              target="_blank"
            >
              <Button colorScheme="blue" variant="outline" size="md" style={{ whiteSpace: 'initial' }}>
                <Text fontSize="md" noOfLines={3}>
                  {project?.title}
                </Text>
              </Button>
            </Link>
            <Text>Option or Survey Name: {option?.title}</Text>
            <Text>SW Number: {project?.swNumber}</Text>
            <Text>Client: {project?.client}</Text>
            <Text>Country: {project?.country}</Text>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

import { LatLngBoundsLiteral } from 'leaflet';
import { useMap } from 'react-leaflet';

export const useChangeMapView = () => {
  const map = useMap();

  const changeView = (center: [number, number] | undefined, zoom: number, bounds?: LatLngBoundsLiteral) => {
    if (bounds) {
      map.fitBounds(bounds, {
        animate: true,
        duration: 1,
      });
    }

    if (center) {
      map.setView(center, zoom, {
        animate: true,
        duration: 1,
      });
    }
  };

  return {
    changeView,
  };
};

import { atom, selector } from "recoil";


export interface ISeisintelLevel0Data extends GeoJSON.FeatureCollection {
  features: ISeisintelLevel0DataFeature[];
}

interface ISeisintelLevel0DataFeature extends GeoJSON.Feature {
  properties: ISeisintelLevel0DataFeatureProperties;
}

interface ISeisintelLevel0DataFeatureProperties {
  bbox: number[];
  center_lat: number;
  center_lon: number;
  client: string;
  country: string;
  survey_complete: "Yes" | "In Progress" ;
  survey_name: string;
  survey_type: string;
  vessel: string;
  survey_id: string;
  company: string;
}



export const seisintelLevel0Data =  atom<ISeisintelLevel0Data | null>({
  key: "seisintelLevel0Data",
  default: null,
});

export const seisintelLevel1Data =  atom<ISeisintelLevel0Data | null>({
  key: "seisintelLevel1Data",
  default: null,
});

export interface ISeisintelVesselData extends GeoJSON.FeatureCollection {
  features: ISeisintelVesselDataFeature[];
}

interface ISeisintelVesselDataFeature extends GeoJSON.Feature {
  properties: ISeisintelVesselDataFeatureProperties;
}

interface ISeisintelVesselDataFeatureProperties {
  bbox: number[];
  age: string;
  cog: number;
  company: string;
  company_id: string;
  imo: string;
  latitude: number;
  longitude: number;
  recv_time: string;
  sog: number;
  vessel: string;
  
}

// TODO fix this later
const url = process.env.REACT_APP_BACKEND_URL
? `${process.env.REACT_APP_BACKEND_URL}/seisintelwfs`
: 'https://sales-webmap-backend.azurewebsites.net/seisintelwfs';

export const seisintelVesselsState = selector<ISeisintelVesselData>({
  key: "seisintelVessels",
  get: async () => {

    const vesselsFetchParams =  {
      outputFormat: 'application/json',
      count: '250',
      request: 'GetFeature',
      service: 'WFS',
      typeNames: 'seisintel:Latest_Positions',
      version: '2.0.0',
      startIndex: '0',
    };

    const params = new URLSearchParams(vesselsFetchParams);
    const fetchUrl = `${url}?${params.toString()}`;

    const response = await fetch(fetchUrl,
      {
        method: 'GET',
        headers: {
          authorization: `Basic ${process.env.REACT_APP_WEBMAP_ACCESS_TOKEN}`,
        },
      }
      );
    const data = await response.json();
    return data;
  }
});
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { oPolygonsAtom, selectedPolygonAtom } from "../../state/geosupportData";

export const useRouteForPolygon = () => {
  const [searchParams, ] = useSearchParams();
  const oPolygons = useRecoilValue(oPolygonsAtom);
  const setSelectedPolygon = useSetRecoilState(selectedPolygonAtom);



  const routeOptionNumber = searchParams.get('optionNumber');

  useEffect(() => {
    if (routeOptionNumber) {
      const oPolygon = oPolygons.find((oPolygon) => oPolygon.optionNumber === routeOptionNumber);
      if (oPolygon) {
        setSelectedPolygon(oPolygon);
      }
    }
  }, [oPolygons, routeOptionNumber]);
}

import './App.css';
import { useIsSignedIn } from './hooks/useIsSignedIn';
import BasicMap from './components/BasicMap';
import { Box, Card, Flex, Heading } from '@chakra-ui/react';
import { Navbar } from './components/Navbar';
import { SideDrawer } from './components/SideDrawer';
import { OptionPolygonsGrouped } from './components/OptionPolygonsGrouped';
import { DynamicLayers } from './components/DynamicLayers';
import { SWLayersControlOverlay } from './components/SWLayersControl';
import { useRouteForPolygon } from './hooks/geosupport/useRouteForPolygon';
import { Login } from '@microsoft/mgt-react';
import { PopupModal } from './components/PopupModal';
import { SelectedPolygon } from './components/SelectedPolygon';
import { layerNames } from './constants/layerNames';
import { appInsights } from './util/appInsights';
import { useEffect } from 'react';

function App() {
  const [signedIn] = useIsSignedIn();

  useEffect(() => {
    appInsights.trackEvent({ name: 'App Loaded' });
  }, []);

  useRouteForPolygon();

  return (
    <Box>
      <Navbar />

      {signedIn ? (
        <BasicMap>
          <PopupModal />
          <SWLayersControlOverlay name={layerNames.BID_OUTLINES.name} type={layerNames.BID_OUTLINES.type}>
            <OptionPolygonsGrouped />
          </SWLayersControlOverlay>

          <DynamicLayers />
          <SelectedPolygon />
          <SideDrawer />
        </BasicMap>
      ) : (
        <Flex
          height="92vh"
          width="100%"
          alignItems="center"
          justifyContent="center"
          direction="column"
          background="lightgray"
        >
          <Card p={8}>
            <Heading as="h1" size="xl">
              Welcome to the SW Webmap
            </Heading>
            <Heading
              as="h2"
              size="l"
              mt={4}
              mb={8}
              color="gray.500"
              fontWeight="normal"
              fontStyle="italic"
              textAlign="center"
            >
              First Port of Call for GIS Data
            </Heading>
            <Box p={4}>
              <Heading as="h2" size="l">
                Please sign in to continue
              </Heading>
            </Box>
            <Login />
          </Card>
        </Flex>
      )}

      {/* <HelperLogForSharepointLibId /> */}
    </Box>
  );
}

export default App;

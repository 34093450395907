import { Get } from '@microsoft/mgt-react';
import { IListItemWithKmlUrl } from '../../schemas/IListItemWithKmlUrl';

export const GetSurveys: React.FC<{
  handleLoadingFinished: () => void;
  handleSurveysUpdate: (surveys: IListItemWithKmlUrl[]) => void;
}> = ({ handleLoadingFinished, handleSurveysUpdate }) => (
  <Get
    resource="sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/9f63692c-5e84-4247-bfd0-803f93d1279b/items?expand=fields(select=Title,Region,Survey_Number,Survey_KML_Link)"
    dataChange={(e) => {
      handleLoadingFinished();
      const newE = e as any;
      const eValue = newE.detail.response.value as any[];
      let newSurveys: IListItemWithKmlUrl[] = [];
      eValue.forEach((o) => {
        const { Title: title, Survey_Number: itemNumber, Survey_KML_Link: kml } = o.fields;
        const { Url: url } = kml || {};
        // removed check on existing url as we are caching the kmls anyway.
        // const existingUrl = url && newSurveys.find((o) => o.url === url);

        const split1 = url && url.split('/sites/');
        const split2 = split1 && split1.length > 1 && split1[1].replace('%20', ' ').split('/Shared Documents/');

        if (split2 && split2.length > 1) {
          const [site, path] = split2;
          const survey = {
            title,
            itemNumber,
            site,
            path,
            url,
          };
          newSurveys.push(survey);
        }
      });
      handleSurveysUpdate(newSurveys);
    }}
    maxPages={0}
  />
);

import { Providers } from "@microsoft/mgt-element";
import { useEffect, useRef, useState } from "react";
import { Libraries } from "../constants/SPLibraryIds";
import { getJsonFromArray } from "../util/getJsonFromArray";
import { isValidLat, isValidLong } from "../util/isValidLatLong";
import { useIsSignedIn } from "./useIsSignedIn";

interface UseFetchFromExcelProps {
  filepath: string;
}

export const useFetchFromExcel = ({filepath}: UseFetchFromExcelProps) => {
  const [signedIn] = useIsSignedIn();
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [header, setHeader] = useState<any>();
  const hasRetrieved = useRef(false);



  useEffect(() => {
    if (signedIn) {
      (async () => {
        if(hasRetrieved.current) {
          return;
        }
        try{
        setIsLoading(true);
        const client = Providers.client;
        const resource = `sites/${Libraries['GeoSupportShearwater'].id}/drive/root:${filepath}`;
        const workbookData = await client.api(resource).get();

        const { id: workbookId } = workbookData;

        const resourceX = `sites/${Libraries['GeoSupportShearwater'].id}/drive/items/${workbookId}/workbook/worksheets`;
        const worksheetsList = await client.api(resourceX).get();
        const worksheets = worksheetsList.value.map((worksheet: { name: any }) => worksheet.name);

        const firstWorksheet = worksheets[0];

        const resource2 = `sites/${Libraries['GeoSupportShearwater'].id}/drive/items/${workbookId}/workbook/worksheets/${firstWorksheet}`;
        const worksheetData = await client.api(resource2).get();

        const { id: worksheetId } = worksheetData;

        const resource3 = `sites/${Libraries['GeoSupportShearwater'].id}/drive/items/${workbookId}/workbook/worksheets/${worksheetId}/usedRange`;
        const worksheetRangeData = await client.api(resource3).get();

        const { values } = worksheetRangeData;

        const header = values[0].filter((value: string) => value !== '');
        if(!(header.includes('LATITUDE') && header.includes('LONGITUDE'))){
          throw new Error('Header does not contain LATITUDE and LONGITUDE');
        }
        setHeader(header);
        const previousSurveys = getJsonFromArray(values);
        const filteredSurveys = previousSurveys.filter((survey: any) => isValidLat(survey.LATITUDE) && isValidLong(survey.LONGITUDE));
        setItems(filteredSurveys);
        hasRetrieved.current = true;
        setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [filepath, signedIn]);

  return {header, items, isLoading};
}
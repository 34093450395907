import { LayerGroup } from 'react-leaflet';
import { useRecoilValue } from 'recoil';
import { filterByYearsAtom, oPolygonsAtom, projectsAtom } from '../state/geosupportData';
import { getProjectYear } from '../util/geosupport_utils/getProject';
import { OptionPolygon } from './OptionPolygon';

export const OptionPolygonsGrouped: React.FC = () => {
  const oPolygons = useRecoilValue(oPolygonsAtom);
  const filterByYears = useRecoilValue(filterByYearsAtom);
  const projects = useRecoilValue(projectsAtom);
  const polygons = oPolygons.filter((polygon) =>
    filterByYears.includes(getProjectYear(polygon.optionNumber, projects) ?? 0)
  );
  return (
    <LayerGroup>
      {polygons && polygons.map((polygon) => <OptionPolygon key={polygon.optionNumber} polygon={polygon} />)}
    </LayerGroup>
  );
};

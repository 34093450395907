import { useEffect } from 'react';
import { Pane, Polygon } from 'react-leaflet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useChangeMapView } from '../hooks/useChangeMapView';
import { selectedPolygonAtom } from '../state/geosupportData';
import { showSideDrawerViewDetails } from '../state/sideDrawerViewDetails';

export const SelectedPolygon = () => {
  const selectedPolygon = useRecoilValue(selectedPolygonAtom);
  const setViewDetails = useSetRecoilState(showSideDrawerViewDetails);
  const { changeView } = useChangeMapView();

  useEffect(() => {
    if (selectedPolygon) {
      changeView(selectedPolygon.coordinates ? selectedPolygon.coordinates[0][0] : undefined, 9);
    }
  }, [changeView, selectedPolygon]);

  if (!selectedPolygon?.coordinates) return <></>;

  return (
    <Pane name="Selected Polygon" style={{ zIndex: 1000 }}>
      <Polygon
        positions={selectedPolygon.coordinates}
        pathOptions={{ color: 'red' }}
        eventHandlers={{
          click: (e) => {
            setViewDetails(true);
          },
        }}
      />
    </Pane>
  );
};

import { Button, Input, InputGroup, InputRightElement, Spinner, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { edinLayersState, layerState } from '../../state/map';
import { LayerMenuItem } from '../SWLayersControl';

export const EdinLayersMenu = () => {
  const edinLayers = useRecoilValue(edinLayersState);
  return (
    <>
      <Stack direction="row" spacing={2} mb={4} alignItems="center">
        <Text>Need zoom level 6 to see these</Text>
        <Button
          size="xs"
          onClick={() => {
            window.open(
              'https://shearwatergeonorway.sharepoint.com/:w:/r/sites/SWWebmap/Shared%20Documents/Documentation/EDIN_Webmap_help.docx?d=waa596e694d2d4fdeaefa9d1413634e21&csf=1&web=1&e=0Mv0bw',
              '_blank'
            );
          }}
        >
          EDIN Display & Filtering help
        </Button>
      </Stack>
      <Stack direction="column" mb={2} spacing={8}>
        {edinLayers.map((layer) => (
          <Stack direction="column" key={layer.name} spacing={4}>
            <Stack direction="row" spacing={2}>
              <LayerMenuItem key={layer.name} layerId={layer.name} /> {layer.isLoading && layer.visible && <Spinner />}
            </Stack>
            <EdinMenuFilter name={layer.name} />
          </Stack>
        ))}
      </Stack>
    </>
  );
};

const EdinMenuFilter = ({ name }: { name: string }) => {
  const [layer, setLayer] = useRecoilState(layerState(name));
  const [filterValue, setFilterValue] = useState(layer.edinFilter || '');

  const showSetButton = layer.edinFilter !== filterValue && (filterValue || layer.edinFilter);

  return (
    <>
      {layer.visible && (
        <InputGroup>
          <Input
            placeholder="Filter eg operator_name ilike '%bp%' and general_contract_type Like 'CCUS%'"
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
            }}
            size="sm"
          />
          {showSetButton && (
            <InputRightElement width="2.5rem">
              <Button
                variant="ghost"
                onClick={() => {
                  setLayer({ ...layer, edinFilter: filterValue });
                }}
                size="xs"
                mb={2}
              >
                Set
              </Button>
            </InputRightElement>
          )}
        </InputGroup>
      )}
    </>
  );
};

import L from 'leaflet';
import { Pane, GeoJSON } from 'react-leaflet';
import { useRecoilValue } from 'recoil';
import { seisintelVesselsState } from '../../state/seisintelData';
import { createFormattedPopupContent } from '../../util/createFormattedPopupContent';
import { SWLayersControlOverlay } from '../SWLayersControl';
import './SeisintelVessels.css';

export const SeisintelVessels = () => {
  const vessels = useRecoilValue(seisintelVesselsState);

  if (!vessels) {
    return <></>;
  }

  const pointToLayer = (feature: { properties: any }, latlng: L.LatLngExpression) => {
    const { properties } = feature;
    const { cog = 0, sog = 0 } = properties;

    // Create a new Leaflet divIcon, using the SVG path
    const vesselIcon = L.divIcon({
      className: '',
      iconSize: L.point(2, 2), // Adjust iconSize for larger/smaller arrows
      html: boatHTML(cog, sog),
    });

    return L.marker(latlng, { icon: vesselIcon });
  };

  return (
    <>
      <SWLayersControlOverlay name="Seisintel_vessels" type="Seisintel">
        <Pane name="Seisintel_vessels" style={{ zIndex: 5 }}>
          <GeoJSON
            data={vessels}
            pointToLayer={pointToLayer}
            onEachFeature={(feature, layer) => {
              // bind all properties to popup
              const popupContent = createFormattedPopupContent(feature.properties);
              layer.bindPopup(popupContent);
              if (feature.properties && feature.properties.vessel) {
                layer.bindTooltip(feature.properties.vessel, {
                  permanent: true,
                  direction: 'center',
                  className: 'my-labels',
                });
              }
            }}
          />
        </Pane>
      </SWLayersControlOverlay>
    </>
  );
};

const boatHTML = (cog: number, sog: number) =>
  `<div style="transform: rotate(${cog}deg); width: 7px; height: 14px; display: flex; justify-content: center; align-items: center;">
      <svg width="14" height="35" viewBox="0 0 14 35" fill="${getColor(sog)}" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 11.5V34H13.5V11.5L7 1L0.5 11.5Z" stroke="black"/>
</svg>
      </div>`;

const getColor = (sog: number) => {
  // less than 1 blue
  // 1 - 3.5 orange
  // 3.5 - 5.5 green
  // more than 5.5 white

  if (sog < 1) {
    return 'blue';
  }

  if (sog < 3.5) {
    return 'orange';
  }

  if (sog < 5.5) {
    return 'green';
  }

  return 'white';
};

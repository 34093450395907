export const createFormattedPopupContent = (properties: Record<string, any>) => {
  const propertiesFiltered = Object.keys(properties)
    .filter((key) => key && properties[key] && properties[key] !== 'null')
    .map((key) => {
      return `<strong>${key}</strong>: ${formatProperty(properties[key])}`;
    });

  const chunks = [];
  for (let i = 0; i < propertiesFiltered.length; i += 15) {
    chunks.push(propertiesFiltered.slice(i, i + 15));
  }

  const popupContent = chunks.map(chunk => `<div>${chunk.join('<br />')}</div>`).join('');

  return `<div style="display: flex">${popupContent}</div>`;
};

const formatProperty = (property: any) => {
  if (typeof property !== 'string') {
    return JSON.stringify(property);
  }

  // if string is a link, return a link
  if (property.startsWith('http')) {
    return `<a href="${property}" target="_blank">${property}</a>`;
  }

  // if string is a date, return a date
  if (property.match(/\d{4}-\d{2}-\d{2}/)) {
    return new Date(property).toLocaleDateString();
  }

  return property;
};

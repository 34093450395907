import { Button, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react';
import { LatLngBoundsLiteral } from 'leaflet';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useChangeMapView } from '../../hooks/useChangeMapView';
import { ISeisintelLevel0Data, seisintelLevel0Data } from '../../state/seisintelData';

// types of properties in the data
type IProperties = keyof ISeisintelLevel0Data['features'][0]['properties'];

export const SeisintelLevel0Table: React.FC = () => {
  const level0Data = useRecoilValue(seisintelLevel0Data);

  const bboxToLatLngTuple = (bbox: number[]) => {
    return [[bbox[1], bbox[0]] as [number, number], [bbox[3], bbox[2]] as [number, number]] as LatLngBoundsLiteral;
  };

  const { changeView } = useChangeMapView();

  if (!level0Data) {
    return null;
  }

  return (
    <Table size={'sm'}>
      <Thead>
        <Tr>
          <Th>
            <ToggleButton name="Survey Status" property="survey_complete" />
          </Th>
          <Th>
            <ToggleButton name="Vessel" property="vessel" />
          </Th>
          <Th>
            <ToggleButton name="Survey Name" property="survey_name" />
          </Th>
          <Th>
            <ToggleButton name="Client" property="client" />
          </Th>
          <Th>
            <ToggleButton name="Survey Company" property="company" />
          </Th>
          <Th>
            <ToggleButton name="Country" property="country" />
          </Th>
          <Th>Zoom</Th>
        </Tr>
      </Thead>
      <Tbody overflow={'scroll'} maxH={400} w={'auto'}>
        {level0Data.features.map((row) => (
          <Tr
            key={row.id}
            onClick={() => {
              console.log({ row });
              if (row.properties.bbox) {
                console.log('bbox latlong tuple', bboxToLatLngTuple(row.properties.bbox));
                changeView(undefined, 4, bboxToLatLngTuple(row.properties.bbox));
              } else {
                changeView([row.properties.center_lon, row.properties.center_lat], 4);
              }
            }}
            cursor={'pointer'}
            _hover={{
              background: 'gray.100',
            }}
          >
            <Td>{row.properties.survey_complete === 'Yes' ? 'Complete' : 'In Progress'}</Td>
            <Td>{row.properties.vessel}</Td>
            <Td>
              <Text noOfLines={4} maxW={55}>
                {row.properties.survey_name}
              </Text>
            </Td>
            <Td>{row.properties.client}</Td>
            <Td>{row.properties.company}</Td>
            <Td>{row.properties.country}</Td>
            <Td>
              <Button
                size={'sm'}
                variant={'link'}
                onClick={() => {
                  console.log({ row });
                  if (row.properties.bbox) {
                    console.log('bbox latlong tuple', bboxToLatLngTuple(row.properties.bbox));
                    changeView(undefined, 4, bboxToLatLngTuple(row.properties.bbox));
                  } else {
                    changeView([row.properties.center_lon, row.properties.center_lat], 4);
                  }
                }}
              >
                {' '}
                Zoom To{' '}
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const ToggleButton = ({ property, name }: { property: IProperties; name: string }) => {
  const [orderToggle, setOrderToggle] = useState<1 | -1>(-1);
  const [level0Data, setLevel0Data] = useRecoilState(seisintelLevel0Data);

  const orderDataByProperty = (property?: IProperties) => {
    if (!level0Data) {
      return;
    }

    if (!property) {
      return;
    }

    const features = [...level0Data.features];

    const orderedData = features.sort((a, b) => {
      if (a.properties[property] < b.properties[property]) {
        return -1 * (orderToggle || -1);
      }
      if (a.properties[property] > b.properties[property]) {
        return 1 * (orderToggle || 1);
      }
      return 0;
    });
    setLevel0Data({ ...level0Data, features: orderedData });
    setOrderToggle((prev) => (prev * -1) as 1 | -1);
  };

  return (
    <Button size={'xs'} variant="unstyled" onClick={() => orderDataByProperty(property)}>
      {name.toUpperCase()}
    </Button>
  );
};

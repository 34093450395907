import { Box, Button, Link, Text } from '@chakra-ui/react';
import { Popup } from 'react-leaflet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { popupDataState, popupShowMoreState } from '../state/popup';
import './SWPopup.css';

export const SWPopup = () => {
  const popupData = useRecoilValue(popupDataState);

  if (!popupData) return <></>;

  return (
    <Popup position={popupData.latlng} autoPan={true}>
      <SWPopupContent />
    </Popup>
  );
};

const SWPopupContent = () => {
  const popupData = useRecoilValue(popupDataState);
  const setShowMore = useSetRecoilState(popupShowMoreState);

  if (!popupData) return null;

  const properties = popupData?.properties;

  if (!properties) return null;

  const allRows = getFormattedRows(properties);
  const limitedRows = allRows.slice(0, 20);

  return (
    <div>
      {limitedRows}
      {allRows.length > 20 && (
        <Button size="xm" variant="link" onClick={() => setShowMore(true)}>
          Show More
        </Button>
      )}
    </div>
  );
};

export const getFormattedRows = (properties: any) => {
  return (
    Object.keys(properties)
      .filter((key) => key && properties[key] && properties[key] !== 'null')
      // sort so that anything with objectid or _id is at the end
      .sort((a, b) => {
        if (a.includes('objectid') || a.includes('_id')) {
          return 1;
        }

        if (b.includes('objectid') || b.includes('_id')) {
          return -1;
        }

        return 0;
      })
      .map((key) => (
        <Box key={key}>
          <Text fontSize="sm">
            <strong>{key}</strong>: {formatProperty(properties[key], key)}
          </Text>
        </Box>
      ))
  );
};

const formatProperty = (property: any, key?: string) => {
  if (key?.toLowerCase().includes('date')) {
    return new Date(property).toLocaleDateString();
  }

  if (typeof property !== 'string') {
    return JSON.stringify(property);
  }

  // if string is a link, return a link
  if (property.startsWith('http')) {
    return (
      <Link href={property} target="_blank" rel="noreferrer">
        {property}
      </Link>
    );
  }

  // if string is a date, return a date
  if (property.match(/\d{4}-\d{2}-\d{2}/)) {
    return new Date(property).toLocaleDateString();
  }

  return property;
};

import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { oPolygonsAtom, projectsAtom, selectedPolygonAtom } from "../../state/geosupportData";
import { getProjectYear } from "../../util/geosupport_utils/getProject";

export const useSearchSelect = () => {
  const oPolygons = useRecoilValue(oPolygonsAtom);
  const projects = useRecoilValue(projectsAtom);
  const toast = useToast();
  const [, setSearchParams] = useSearchParams();
  const setSelectedPolygon = useSetRecoilState(selectedPolygonAtom);
  const [toggleFlyTo, setToggleFlyTo] = useState(false);





  const handleSearchSelect = useCallback(
    (optionNumber: string) => {
      // find the polygon with the optionNumber
      const foundPolygon = oPolygons?.find((polygon) => polygon.optionNumber === optionNumber);

      if (!foundPolygon) {
        // find the project with the optionNumber starting with the swNumber of the project
        const projectYear = getProjectYear(optionNumber, projects);

        if (!projectYear) {
          toast({
            title: 'No polygon found',
            description: `No polygon found for option number ${optionNumber}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: `Polygons for ${projectYear} not downloaded`,
            description: `Please download Geosupport polygons for year ${projectYear} to search for this option number`,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          });
        }

        setSearchParams({});
        setSelectedPolygon(undefined);
        return;
      }

      setSelectedPolygon(foundPolygon);
      setToggleFlyTo((prevToggleFlyTo) => !prevToggleFlyTo);
      setSearchParams({ optionNumber });
    },
    [oPolygons, setSelectedPolygon, setSearchParams, projects, toast]
  );

  return { handleSearchSelect, toggleFlyTo, setToggleFlyTo };
}
import { Box, Checkbox, Text, Stack, Button, Spinner } from '@chakra-ui/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { layerNames } from '../../constants/layerNames';
import { filterByYearsAtom, isLoadingInitialGeosupportDataAtom, oPolygonsAtom } from '../../state/geosupportData';
import { geosupportLayersState, layerState } from '../../state/map';
import { coordinatesToGeoJsonFeatureCollection } from '../../util/geoJSONFromPolygonArray';
import { LayerMenuItem } from '../SWLayersControl';
import { InitialGeosupportDataLoad } from './InitialGeosupportDataLoad';
import { SearchDataControl } from './SearchDataControl';

export const GeosupportMenu: React.FC = () => {
  // const projects = useRecoilValue(projectsAtom);
  const [filterByYears, setFilterByYears] = useRecoilState(filterByYearsAtom);
  const isLoading = useRecoilValue(isLoadingInitialGeosupportDataAtom);
  const setBidItemsLayerState = useSetRecoilState(layerState(layerNames.BID_OUTLINES.name));

  const refetchAllData = () => {
    // clear session storage
    sessionStorage.clear();

    // refresh the page
    window.location.reload();
  };

  // const allProjectYears = projects?.map((p) => p.createdDateTime.getFullYear());
  // const uniqueProjectYears = allProjectYears?.filter((year, index, self) => self.indexOf(year) === index);

  // all years from 2019 to current year
  const uniqueProjectYears = Array.from({ length: new Date().getFullYear() - 2019 + 1 }, (_, i) => 2019 + i);
  const uniqueProjectYearsSorted = uniqueProjectYears?.sort((a, b) => b - a);

  const isLoadingInitialGeosupportData =
    isLoading.projects || isLoading.options || isLoading.surveys || isLoading.decoratedProjectData;

  return (
    <Box p={4}>
      {isLoadingInitialGeosupportData ? (
        <Spinner />
      ) : (
        <>
          <GeosupportLayersMenu />
          <SearchDataControl />

          <Text size="md" fontWeight="bold" mt={4}>
            Select years below to load data
          </Text>
          <Stack spacing={4} p={4}>
            {uniqueProjectYearsSorted?.map((year) => (
              <Checkbox
                size={'sm'}
                key={year}
                isChecked={filterByYears.includes(year)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFilterByYears([...filterByYears, year]);
                    setBidItemsLayerState((prev) => ({ ...prev, visible: true }));
                  } else {
                    setFilterByYears(filterByYears.filter((y) => y !== year));
                  }
                }}
              >
                {year}
              </Checkbox>
            ))}
          </Stack>
          <Button size="sm" onClick={refetchAllData}>
            Clear cache and refresh data
          </Button>
          <ExportDataButton />
        </>
      )}
      <InitialGeosupportDataLoad />
    </Box>
  );
};

const GeosupportLayersMenu = () => {
  const seisintelLayers = useRecoilValue(geosupportLayersState);
  return (
    <Stack direction="row" mb={2} spacing={4}>
      {seisintelLayers.map((layer) => (
        <LayerMenuItem key={layer.name} layerId={layer.name} />
      ))}
    </Stack>
  );
};

const ExportDataButton = () => {
  const polygons = useRecoilValue(oPolygonsAtom);

  if (!polygons || polygons.length === 0) return null;

  return (
    <Button
      size="sm"
      onClick={() => {
        const dataStr = JSON.stringify(coordinatesToGeoJsonFeatureCollection(polygons));
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

        const exportFileDefaultName = 'data.json';

        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
      }}
    >
      Export Data
    </Button>
  );
};

import { Box, Checkbox, Divider, Text, useToast } from '@chakra-ui/react';
import { Suspense, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { allLayersGroupedState, layerIdsState, layerState } from '../state/map';
import { appInsights } from '../util/appInsights';

type SWLayersControlMenuProps = {
  layersTypeFilter?: string[];
  onlyShowIsChecked?: boolean;
};

export const SWLayersControlMenu: React.FC<SWLayersControlMenuProps> = ({
  layersTypeFilter,
  onlyShowIsChecked = false,
}) => {
  const allLayersGrouped = useRecoilValue(allLayersGroupedState);

  return (
    <Box p={2} bg="white" borderRadius="md">
      {Object.keys(allLayersGrouped)
        .filter((layerType) => !layersTypeFilter || layersTypeFilter.includes(layerType))
        .map((layerType) => (
          <Box key={layerType} mt={4}>
            <Text fontWeight="bold" size="lg" mb={2}>
              {layerType}
            </Text>
            {allLayersGrouped[layerType].map((layer) => (
              <Box key={layer.name}>
                <LayerMenuItem layerId={layer.name} onlyShowIsChecked={onlyShowIsChecked} />
              </Box>
            ))}
            <Divider mt={8} />
          </Box>
        ))}
    </Box>
  );
};

export const LayerMenuItem = ({
  layerId,
  onlyShowIsChecked = false,
}: {
  layerId: string;
  onlyShowIsChecked?: boolean;
}) => {
  const [layer, setLayer] = useRecoilState(layerState(layerId));
  const toast = useToast();

  const tabName = layer.type === 'Background' ? 'Others' : layer.type;

  if (onlyShowIsChecked && !layer.visible) return null;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && onlyShowIsChecked) {
      toast({
        title: `Layer removed.`,
        description: `You can re-instate it from ${tabName ?? 'Others'} tab.`,
        status: 'info',
        duration: 9000,
        isClosable: true,
      });
    }

    setLayer({ ...layer, visible: e.target.checked });
  };

  return (
    <Box>
      <Checkbox isChecked={layer.visible} onChange={onChange} size="sm">
        {layerId}
      </Checkbox>
    </Box>
  );
};

type SWLayersControlOverlayProps = {
  checked?: boolean;
  name: string;
  type?: string;
  children: React.ReactNode;
  edinFilter?: string;
};

export const SWLayersControlOverlay: React.FC<SWLayersControlOverlayProps> = ({
  checked = false,
  name,
  type,
  children,
  edinFilter,
}) => {
  const layerIds = useRecoilValue(layerIdsState);
  const [layer, setLayer] = useRecoilState(layerState(name));
  useEffect(() => {
    if (!name || layerIds.includes(name)) return;
    setLayer({ name, visible: checked, isLoading: false, type, edinFilter });
  }, [layerIds, checked, name, type, setLayer, edinFilter]);

  useEffect(() => {
    if (layer.visible) {
      appInsights.trackEvent({ name: 'Layer Added', properties: { layer: name } });
    }
  }, [layer.visible, name]);
  return <Suspense fallback={<div>Loading...</div>}>{layer.visible && children}</Suspense>;
};

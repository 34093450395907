import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { edinTokenAtom } from '../state/edin';

export const useEdinToken = () => {
  const [edinToken, setEdinToken] = useRecoilState(edinTokenAtom);

  const givenToken = process.env.REACT_APP_EDIN_ACCESS_TOKEN;

  const generateToken = useCallback( async () => {
    console.log('fetching token');

    const fetchUrl = process.env.REACT_APP_BACKEND_URL ? `${process.env.REACT_APP_BACKEND_URL}/edin/token` : 'https://sales-webmap-backend.azurewebsites.net/edin/token';

    const res = await fetch(fetchUrl, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Basic ${process.env.REACT_APP_WEBMAP_ACCESS_TOKEN}`,
      },
    });

    const token = await res.json();

    console.log({token})
    setEdinToken(token);
  }, [setEdinToken]);

  useEffect(() => {
    generateToken();
  }, [generateToken]);

  // every 5 minutes check if token expiry time is less than 5 minutes away
  useEffect(() => {
    const interval = setInterval(() => {
      if (edinToken && edinToken.expires - Date.now() < 300000) {
        generateToken();
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [edinToken, generateToken]);

  if (givenToken) {
    return { edinToken: { token: givenToken, expires: 0 }, generateToken: () => {} };
  }

  return { edinToken, generateToken };
};

import { Get } from '@microsoft/mgt-react';

export interface IProject {
  title: string;
  swNumber: string;
  client?: string;
  tenderOrBQ: string;
  country?: string;
  createdDateTime: Date;
  sharepointLibrary?: string;
  sharepointFolder?: string;
  site: string;
  kmlFolderPath?: string;
}

const KML_FOLDER_PATH = '05_Maps/00_Boundaries/KML';

export const GetProjects: React.FC<{
  handleLoadingFinished: () => void;
  handleProjectsUpdate: (projects: IProject[]) => void;
}> = ({ handleLoadingFinished, handleProjectsUpdate }) => (
  <Get
    resource="sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/84d6b574-d4c5-4751-a06e-b736b68558de/items?expand=fields(select=Title,Shearwater_Number, Client, Tender$2FBQ, Country, Sharepoint_Library, SharepointFolder)"
    dataChange={(e) => {
      handleLoadingFinished();
      const newE = e as any;
      const eValue = newE.detail.response.value as any[];
      const newProjects = eValue.map((p) => {
        const {
          Title: title,
          Shearwater_Number: swNumber,
          Client: client,
          'Tender/BQ': tenderOrBQ,
          Country: country,
          Sharepoint_Library: sharepointLibrary,
          SharepointFolder: sharepointFolder,
        } = p.fields;
        const { createdDateTime: createdDateTimeString } = p;
        const createdDateTime = new Date(createdDateTimeString);
        const split1 = sharepointLibrary && sharepointLibrary.split('/sites/');
        const split2 = split1 && split1.length > 1 && split1[1].replace('%20', ' ').split('/Shared Documents/');
        return {
          title,
          swNumber,
          client,
          tenderOrBQ,
          country,
          createdDateTime,
          sharepointLibrary,
          sharepointFolder,
          site: split2[0],
          kmlFolderPath: `${sharepointFolder}/${KML_FOLDER_PATH}`,
        };
      });
      handleProjectsUpdate(newProjects.filter((p) => !p.swNumber.startsWith('SWP')));
    }}
    maxPages={0}
  />
);

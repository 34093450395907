import { Get } from '@microsoft/mgt-react';

export interface IDynamicLayer {
  title: string;
  filetype: string;
  filepath: string;
  layerColour: string;
}

export const GetDynamicLayers: React.FC<{
  handleLoadingFinished: () => void;
  handleDynamicLayersUpdate: (layers: IDynamicLayer[]) => void;
}> = ({ handleLoadingFinished, handleDynamicLayersUpdate }) => (
  <Get
    resource="/sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/webmap-layers/items?expand=fields(select=layerColour,Title,filetype,Colour,Layer_Path)"
    // resource="https://graph.microsoft.com/v1.0/sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/webmap-layers"
    dataChange={(e) => {
      handleLoadingFinished();
      const newE = e as any;
      const eValue = newE.detail.response.value as any[];
      const newLayers = eValue.map((p) => {
        const { Title: title, filetype, Colour: layerColour, Layer_Path: filepath } = p.fields;
        return { title, filetype, layerColour, filepath };
      });
      handleDynamicLayersUpdate(newLayers);
    }}
    maxPages={0}
  />
);

import { Box, Button, Heading, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useMap } from 'react-leaflet';
import { useRecoilState } from 'recoil';
import { layerMenuIsHiddenState } from '../state/layerMenu';
import { EdinLayersMenu } from './EDIN/EdinLayersMenu';
import { GeosupportMenu } from './Geosupport/GeosupportMenu';
import { SeisintelFilters } from './Seisintel/SeisintelFilters';
import { SWLayersControlMenu } from './SWLayersControl';

export const TabbedMenu = () => {
  const [isHidden, setIsHidden] = useRecoilState(layerMenuIsHiddenState);
  const map = useMap();
  // const [insideMenu, setInsideMenu] = useState(false);

  //  Removed this because of request from Zoe. Any click outside does not lead to menu being closed.
  //  useMapEvent('click', () => {
  //   if (!insideMenu) {
  //     setIsHidden(true);
  //   }
  // });

  return (
    <Box
      position="absolute"
      top={1}
      left={2}
      zIndex={1000}
      p={4}
      bg="white"
      borderRadius="md"
      w="auto"
      maxH="90vh"
      outline="2px solid #ccc"
      overflow={'scroll'}
      // hide the scroll bar
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '24px',
        },
      }}
      // We have to do this since tabbed menu is a child of the map container. If we move it out, we can remove this.
      // but if we move it out, we start getting some layout issues.
      onMouseEnter={() => {
        // setInsideMenu(true);
        map.scrollWheelZoom.disable();
        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }}
      onMouseLeave={() => {
        // setInsideMenu(false);
        map.scrollWheelZoom.enable();
        map.dragging.enable();
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        mb={2}
        onClick={() => {
          setIsHidden(!isHidden);
        }}
      >
        <Heading size="sm">Layers Menu</Heading>
        <Button size="sm" variant="outline">
          {isHidden ? 'Show' : 'Hide'}
        </Button>
      </Stack>
      <Box display={isHidden ? 'none' : 'block'} mb={4}>
        <Tabs>
          <TabList>
            <Tab>Selected Layers</Tab>
            <Tab>Seisintel</Tab>
            <Tab>Geosupport</Tab>
            <Tab> EDIN (S&P)</Tab>
            <Tab> Others</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <SWLayersControlMenu onlyShowIsChecked />
            </TabPanel>
            <TabPanel>
              <SeisintelFilters />
            </TabPanel>
            <TabPanel>
              <GeosupportMenu />
            </TabPanel>
            <TabPanel>
              <EdinLayersMenu />
            </TabPanel>
            <TabPanel>
              <SWLayersControlMenu layersTypeFilter={['Background', 'Others']} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

import { Providers } from '@microsoft/mgt-element';
import { useEffect, useRef, useState } from 'react';
import { Libraries } from '../constants/SPLibraryIds';
import { useIsSignedIn } from './useIsSignedIn';

interface UseFetchFromKMLProps {
  filepath: string;
}

export const useFetchFromKML = ({ filepath }: UseFetchFromKMLProps) => {
  const [signedIn] = useIsSignedIn();
  const [kml, setKml] = useState<Document | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const hasRetrieved = useRef(false);

  useEffect(() => {
    if (signedIn) {
      (async () => {
        if (hasRetrieved.current) {
          return;
        }
        try {
          setIsLoading(true);
          const client = Providers.client;
          const resource = `sites/${Libraries['GeoSupportShearwater'].id}/drive/root:${filepath}`;
          const kmlDataUrl = await client
            .api(resource)
            .get()
            .then((res) => {
              // console.log('res', res);
              return res;
            })
            .then((res) => {
              const dataUrl = res['@microsoft.graph.downloadUrl'];
              // console.log('dataUrl', dataUrl);
              return dataUrl;
            });

          await fetch(kmlDataUrl)
            .then((res) => res.text())
            .then((kmlText) => {
              const parser = new DOMParser();
              const kml = parser.parseFromString(kmlText, 'text/xml');
              setKml(kml);
            });
          hasRetrieved.current = true;
          setIsLoading(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [filepath, signedIn]);

  return { kml, isLoading };
};

import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { allSearchableItemsAtom } from "../../state/geosupportData";

export type DownloadStatus = {
  total: number;
  downloaded: number;
  inProgress: number;
  error: number;
};

export const useDownloadStatus = () => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>({
    total: 0,
    downloaded: 0,
    inProgress: 0,
    error: 0,
  });

  const allListItemStatuses = useRef<{
    [key: string]: { status: 'notStarted' | 'inProgress' | 'downloaded' | 'error' };
  }>({});

  const allSearchableItems = useRecoilValue(allSearchableItemsAtom);

  // poll the ref to get loading status
  useEffect(() => {
    let inProgress = null;

    const updateStatuses = () => {
      inProgress = Object.values(allListItemStatuses.current).filter((status: any) => status.status === 'inProgress').length;
      const downloaded = Object.values(allListItemStatuses.current).filter(
        (status: any) => status.status === 'downloaded'
      ).length;
      const error = Object.values(allListItemStatuses.current).filter((status: any) => status.status === 'error').length;
      const total = allSearchableItems.length;
      setDownloadStatus({
        inProgress,
        downloaded,
        error,
        total,
      });
    };

    updateStatuses();

    const interval = setInterval(updateStatuses, 200);
    if (inProgress === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [allSearchableItems]);

  return { downloadStatus, allListItemStatuses };

}

import { Providers } from '@microsoft/mgt-element';
import { useEffect, useState } from 'react';
import { useIsSignedIn } from './useIsSignedIn';

type FetchSPListDataProps = {
  listName: string;
  libraryId: string;
}

export const useFetchSPListData = <T> ({listName, libraryId } :FetchSPListDataProps ): {listData: T|null, fetchListData: () => {}} => {

  const [listData, setListData] = useState<T|null>(null);

  const resource = `/sites/${libraryId}/lists/${listName}/items?expand=fields`

  const client = Providers.client;

  const signedIn = useIsSignedIn();

  const fetchListData = async () => {
    if (signedIn) {
      const response = await client?.api(resource).get();
      return response as T;
    }
    return null;
  }

  useEffect(() => {
    if (signedIn && !listData) {
      fetchListData().then((response) => {
        setListData(response);
      });
    }
  }, [listData, signedIn]);

  

  return { listData, fetchListData};
}
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { popupDataState, popupShowMoreState } from '../state/popup';
import { getFormattedRows } from './SWPopup';

export const PopupModal = () => {
  const [showMore, setShowmore] = useRecoilState(popupShowMoreState);
  const popupData = useRecoilValue(popupDataState);

  if (!popupData) return null;

  const properties = popupData?.properties;

  if (!properties) return null;

  const allRows = getFormattedRows(properties);

  // get rows into 3 columns

  return (
    <Modal isOpen={showMore} onClose={() => setShowmore(false)} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>More Info</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={8}>
          {
            // display rows in 3 columns
            <Flex direction="row" flexWrap="wrap">
              <Stack spacing={1} w="33%">
                {allRows.slice(0, Math.ceil(allRows.length / 3))}
              </Stack>
              <Stack spacing={1} w="33%">
                {allRows.slice(Math.ceil(allRows.length / 3), Math.ceil((allRows.length / 3) * 2))}
              </Stack>
              <Stack spacing={1} w="33%">
                {allRows.slice(Math.ceil((allRows.length / 3) * 2), allRows.length)}
              </Stack>
            </Flex>
          }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

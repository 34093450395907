import ReactLeafletKml from 'react-leaflet-kml';
import { useFetchFromKML } from '../hooks/useFetchFromKML';
import { SWLayersControlOverlay } from './SWLayersControl';

type KMLFileType = {
  filepath: string;
  title: string;
  colour: string;
};

export const KMLFile: React.FC<KMLFileType> = ({ filepath, title, colour }) => {
  const { kml } = useFetchFromKML({ filepath });

  return (
    <SWLayersControlOverlay name={title}>
      {kml && (
        <ReactLeafletKml
          kml={kml}
          // TODO deal with colour later
        />
      )}
    </SWLayersControlOverlay>
  );
};

export const layerNames = {
  BID_OUTLINES : {
    name: "Bid outlines (Teams)",
    type: "Geosupport",
  },
  BACKGROUND_MAPBOX: {
    name: "Background Map (From Mapbox)",
    type: "Background",
  },
  BACKGROUND_CHART_ARCS: {
    name: "ARCS Chart (From Pisys Marine - Global Protect Only)",
    type: "Background",
  },
  EEZ_BOUNDARIES: {
    name: "Exclusive Economic Zone (EEZ) Boundaries v11",
    type: "Others",
  }
}
import { Box, Heading } from '@chakra-ui/react';
import { Polygon, Tooltip } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { optionsAtom, projectsAtom, selectedPolygonAtom } from '../state/geosupportData';
import { showSideDrawerViewDetails } from '../state/sideDrawerViewDetails';
import { getProject } from '../util/geosupport_utils/getProject';
import { IOPolygon } from '../util/geosupport_utils/loadingDataHelpers';

export type OptionPolygonProps = {
  polygon: IOPolygon;
};

export const OptionPolygon: React.FC<OptionPolygonProps> = ({ polygon }) => {
  const [, setSearchParams] = useSearchParams();
  const [selectedPolygon, setSelectedPolygon] = useRecoilState(selectedPolygonAtom);
  const projects = useRecoilValue(projectsAtom);
  const options = useRecoilValue(optionsAtom);
  const getProjectTitle = (optionNumber: string) => getProject(optionNumber, projects)?.title;
  const getOptionTitle = (optionNumber: string) => options?.find((option) => option.itemNumber === optionNumber)?.title;
  const setViewDetails = useSetRecoilState(showSideDrawerViewDetails);

  if (!polygon.coordinates) return <></>;

  return (
    <Polygon
      key={polygon.optionNumber}
      positions={polygon.coordinates}
      pathOptions={polygon.optionNumber === selectedPolygon?.optionNumber ? { color: 'red' } : { color: 'blue' }}
      eventHandlers={{
        click: (e) => {
          setSearchParams({ optionNumber: polygon.optionNumber });
          setSelectedPolygon(polygon);
          setViewDetails(true);
        },
      }}
    >
      <Tooltip>
        <>
          <Heading fontSize="medium">{getProjectTitle(polygon.optionNumber)}</Heading>
        </>
        <Box>{getOptionTitle(polygon.optionNumber)}</Box>
        <Box>{polygon.optionNumber}</Box>
      </Tooltip>
    </Polygon>
  );
};

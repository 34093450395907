import { Button, Table, Tbody, Td, Th, Thead, Tr, Text, Input, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useChangeMapView } from '../../hooks/useChangeMapView';
import { layerMenuIsHiddenState } from '../../state/layerMenu';
import { seisintelVesselsState } from '../../state/seisintelData';

export const SeisintelVesselsTable: React.FC = () => {
  const [search, setSearch] = useState('');
  const vesselData = useRecoilValue(seisintelVesselsState);

  const setLayerMenuIsHidden = useSetRecoilState(layerMenuIsHiddenState);

  const { changeView } = useChangeMapView();

  if (!vesselData) {
    return null;
  }

  const displayedVesselData = vesselData.features.filter((row) => {
    return (
      row.properties.vessel.toLowerCase().includes(search.toLowerCase()) ||
      row.properties.company.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <>
      <Box pb={4}>
        <Input
          placeholder="Search by Vessel or Company Name"
          size="sm"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          padding={2}
        />
      </Box>
      <Table size={'sm'}>
        <Thead>
          <Tr>
            <Th>Vessel</Th>
            <Th>COG</Th>
            <Th>SOG</Th>
            <Th>Update Age</Th>
            <Th>Company</Th>
            <Th>Zoom</Th>
          </Tr>
        </Thead>
        <Tbody overflow={'scroll'} maxH={400} w={'auto'}>
          {displayedVesselData.map((row) => (
            <Tr
              key={row.id}
              onClick={() => {
                console.log({ row });

                changeView([row.properties.latitude, row.properties.longitude], 7);
                setLayerMenuIsHidden(true);
              }}
              cursor={'pointer'}
              _hover={{
                background: 'gray.100',
              }}
            >
              <Td>{row.properties.vessel}</Td>
              <Td>
                <Text noOfLines={4} maxW={55}>
                  {row.properties.cog} degrees
                </Text>
              </Td>
              <Td>{row.properties.sog} knts</Td>
              <Td>{row.properties.age} seconds</Td>
              <Td>{row.properties.company}</Td>
              <Td>
                <Button
                  size={'sm'}
                  variant={'link'}
                  onClick={() => {
                    changeView([row.properties.latitude, row.properties.longitude], 7);
                  }}
                >
                  {' '}
                  Zoom To{' '}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

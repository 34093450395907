import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IDecoratedListItemWithKmlUrl, IListItemWithKmlUrl } from '../../schemas/IListItemWithKmlUrl';
import {
  allSearchableItemsAtom,
  filterByYearsAtom,
  isLoadingInitialGeosupportDataAtom,
  oPolygonsAtom,
  optionsAtom,
  projectsAtom,
  surveysAtom,
} from '../../state/geosupportData';
import { startMapLoadAtom } from '../../state/map';
import { getProjectYear } from '../../util/geosupport_utils/getProject';
import { GetOptions } from './GetOptions';
import { GetProjects, IProject } from './GetProjects';
import { GetSurveys } from './GetSurveys';
import { Providers } from '@microsoft/mgt-react';
import {
  getProjectKmlFolderPath,
  getResourcePath,
  IFileData,
  updatePolygons,
} from '../../util/geosupport_utils/loadingDataHelpers';
import { useDownloadStatus } from '../../hooks/geosupport/useDownloadStatus';
import { Flex, Text } from '@chakra-ui/react';
import { CenterScreenLoading } from '../CenterScreenLoader';

type FolderFilesResponseShape = {
  value: IFileData[];
};

// We cannot cache the complete response as it exceeds the 5M limit for sessionStorage
const cachedProviderFetchFileData = async (url: string): Promise<FolderFilesResponseShape> => {
  const cacheKey = `__cached__${url}`;
  const cached = sessionStorage.getItem(cacheKey);
  if (cached) {
    return Promise.resolve(JSON.parse(cached));
  }

  return Providers.client
    .api(url)
    .get()
    .then((response: FolderFilesResponseShape) => {
      const { value } = response;

      // delete all properties from value that are not name, webUrl, size, or @microsoft...
      const filteredValue = value
        .map((item) => {
          const { name, webUrl, size } = item;
          const downloadUrl = item['@microsoft.graph.downloadUrl'];

          // if name is not a kml file, return null
          if (!name.endsWith('.kml')) {
            return null;
          }

          return { name, webUrl, '@microsoft.graph.downloadUrl': downloadUrl, size };
        })
        .filter((item) => item !== null);

      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({
          value: filteredValue,
        })
      );
      return response;
    })
    .catch((e) => {
      console.error(e);
      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({
          value: [],
        })
      );
      return { value: [] };
    });
};

export const InitialGeosupportDataLoad = () => {
  const [isLoading, setIsLoading] = useRecoilState(isLoadingInitialGeosupportDataAtom);
  const setStartMapLoad = useSetRecoilState(startMapLoadAtom);
  const [options, setOptions] = useRecoilState(optionsAtom);
  const [surveys, setSurveys] = useRecoilState(surveysAtom);
  const [projects, setProjects] = useRecoilState(projectsAtom);
  const setAllSearchableItems = useSetRecoilState(allSearchableItemsAtom);
  const setOPolygons = useSetRecoilState(oPolygonsAtom);
  const filterByYears = useRecoilValue(filterByYearsAtom);

  const { downloadStatus, allListItemStatuses } = useDownloadStatus();

  // TODO older function remove later
  // const handleSetOPolygons = useCallback(
  //   (optionNumber: string, coordinates: [number, number][][] | null) =>
  //     setOPolygons((prevOPolygons) => {
  //       const newOPolygons = prevOPolygons ? [...prevOPolygons] : [];
  //       const polygon = newOPolygons.find((polygon) => polygon.optionNumber === optionNumber);
  //       if (polygon) {
  //         polygon.coordinates = coordinates;
  //       } else {
  //         newOPolygons.push({
  //           optionNumber,
  //           coordinates,
  //         });
  //       }
  //       return newOPolygons;
  //     }),
  //   []
  // );

  const getDecoratedProjects = useCallback(async (projects?: IProject[]) => {
    setIsLoading((prev) => ({ ...prev, decoratedProjectData: true }));
    const filteredAllRemainingProjects = projects?.filter((project) => project.sharepointFolder);

    if (!filteredAllRemainingProjects) return [];

    const promisesForKmlFolderFiles = filteredAllRemainingProjects?.map(async (project) => {
      try {
        const folderPath = getProjectKmlFolderPath(project);

        const folderFiles = await cachedProviderFetchFileData(folderPath);

        return folderFiles;
      } catch (e) {
        console.log({ e });
        return null;
      }
    });
    const folderFiles: Array<FolderFilesResponseShape | null> = await Promise.all(promisesForKmlFolderFiles);

    const decoratedMultiProjects: IDecoratedListItemWithKmlUrl[] = [];

    filteredAllRemainingProjects.forEach((project, index) => {
      const folderFile = folderFiles[index];
      if (folderFile) {
        const kmlFiles = folderFile.value.filter((file) => file.name.endsWith('.kml'));

        if (kmlFiles.length > 0) {
          kmlFiles.forEach((kmlFile) => {
            decoratedMultiProjects.push({
              ...project,
              type: 'project',
              year: project.createdDateTime.getFullYear(),
              url: kmlFile.webUrl,

              itemNumber: `${project.swNumber}-${kmlFile.name}`,
              path: `${getProjectKmlFolderPath(project)}/${kmlFile.name}`, // this will be not needed as we already have download url
              data: kmlFile,
            });
          });
        }
      }
    });

    setIsLoading((prev) => ({ ...prev, decoratedProjectData: false }));

    return decoratedMultiProjects;
  }, []);

  const getKMLFileMetaData = async (item: IDecoratedListItemWithKmlUrl): Promise<IFileData> => {
    const client = Providers.client;
    const resource = getResourcePath(item, item.type);

    if (item.data) {
      return item.data;
    }

    return client.api(resource).get();
  };

  const handleSetOPolygons = useCallback(
    (optionNumber: string, coordinates: [number, number][][] | null) =>
      setOPolygons((prevOPolygons) => {
        const newOPolygons = [];
        const polygon = prevOPolygons.find((polygon) => polygon.optionNumber === optionNumber);
        if (polygon) {
          newOPolygons.push({
            ...polygon,
            coordinates,
          });
        } else {
          newOPolygons.push({
            optionNumber,
            coordinates,
          });
        }

        // add the remaining polygons
        prevOPolygons.forEach((polygon) => {
          if (polygon.optionNumber !== optionNumber) {
            newOPolygons.push(polygon);
          }
        });

        return newOPolygons;
      }),
    []
  );

  const handleSetPolygonForOption = useCallback(
    (optionNumber: string) => {
      return (coordinates: [number, number][][] | null) => handleSetOPolygons(optionNumber, coordinates);
    },
    [handleSetOPolygons]
  );

  useEffect(() => {
    if (isLoading.options || isLoading.surveys) {
      return;
    }

    (async () => {
      const decoratedOptions = options?.map((option) => {
        return {
          ...option,
          type: 'option',
          year: getProjectYear(option.itemNumber, projects),
        } as IDecoratedListItemWithKmlUrl;
      });

      const decoratedSurveys = surveys?.map((survey) => {
        return {
          ...survey,
          type: 'survey',
          year: getProjectYear(survey.itemNumber, projects),
        } as IDecoratedListItemWithKmlUrl;
      });

      const allSurveyOrOptionItems = [...(decoratedOptions ?? []), ...(decoratedSurveys ?? [])];

      const allRemainingProjects = projects?.filter((project) => {
        return !allSurveyOrOptionItems.find((item) => item.itemNumber.includes(project.swNumber));
      });

      const decoratedProjects = await getDecoratedProjects(allRemainingProjects);

      const allItems = [...allSurveyOrOptionItems, ...decoratedProjects];

      const filteredByYearItems = allItems.filter((item) => filterByYears.includes(item.year));

      setAllSearchableItems(filteredByYearItems);

      let promises: any[] = [];

      (async () => {
        filteredByYearItems.forEach((item) => {
          const resource = getResourcePath(item, item.type);
          allListItemStatuses.current[item.itemNumber] = {
            status: 'inProgress',
          };
          const stringfiedData = sessionStorage.getItem(resource);

          promises.push(
            !!stringfiedData
              ? updatePolygons(item, handleSetPolygonForOption(item.itemNumber), JSON.parse(stringfiedData))
                  .then(() => {
                    allListItemStatuses.current[item.itemNumber] = {
                      status: 'downloaded',
                    };
                  })
                  .catch((error) => {
                    console.log(error);
                    allListItemStatuses.current[item.itemNumber] = {
                      status: 'error',
                    };
                  })
              : getKMLFileMetaData(item)
                  .then((data: IFileData) => {
                    sessionStorage.setItem(resource, JSON.stringify(data));
                    return updatePolygons(item, handleSetPolygonForOption(item.itemNumber), data);
                  })
                  .then(() => {
                    allListItemStatuses.current[item.itemNumber] = {
                      status: 'downloaded',
                    };
                  })
                  .catch((error) => {
                    console.log(error);
                    allListItemStatuses.current[item.itemNumber] = {
                      status: 'error',
                    };
                  })
          );
        });
      })();
      Promise.all(promises);
      setStartMapLoad(true);
    })();
  }, [
    options,
    isLoading.options,
    handleSetPolygonForOption,
    isLoading.surveys,
    surveys,
    filterByYears,
    projects,
    setStartMapLoad,
    allListItemStatuses,
  ]);

  const handleOptionsLoadingFinished = useCallback(() => {
    setIsLoading({ ...isLoading, options: false });
  }, [isLoading]);

  const handleSurveysLoadingFinished = useCallback(() => {
    setIsLoading({ ...isLoading, surveys: false });
  }, [isLoading]);

  const handleProjectsLoadingFinished = useCallback(() => {
    setIsLoading({ ...isLoading, projects: false });
  }, [isLoading]);

  const handleOptionsUpdate = useCallback((options: IListItemWithKmlUrl[]) => {
    setOptions(options);
  }, []);

  const handleSurveysUpdate = useCallback((surveys: IListItemWithKmlUrl[]) => {
    setSurveys(surveys);
  }, []);

  const handleProjectsUpdate = useCallback((projects: IProject[]) => {
    setProjects(projects);
  }, []);

  return (
    <>
      <GetOptions handleOptionsUpdate={handleOptionsUpdate} handleLoadingFinished={handleOptionsLoadingFinished} />
      <GetSurveys handleSurveysUpdate={handleSurveysUpdate} handleLoadingFinished={handleSurveysLoadingFinished} />
      <GetProjects handleProjectsUpdate={handleProjectsUpdate} handleLoadingFinished={handleProjectsLoadingFinished} />
      <Flex p={2} direction="column">
        <Text fontSize="xs">
          Status:: Total: {downloadStatus.total} Downloaded: {downloadStatus.downloaded} InProgress:{' '}
          {downloadStatus.inProgress} Error: {downloadStatus.error}
        </Text>
        {downloadStatus.error > 10 && (
          <Text fontSize="xs" color="red">
            Too many errors, please consider clearing cache and refreshing the data.
          </Text>
        )}
      </Flex>
      <CenterScreenLoading inProgress={downloadStatus.inProgress > 0} />
    </>
  );
};

import { IOPolygon } from "./geosupport_utils/loadingDataHelpers";


function isPolygonFunc(coords: number[][]): boolean {
  // Check if there are at least 4 coordinates
  if (coords.length < 4) {
      return false;
  }

  // Check if the first and last coordinates are the same
  const firstCoord = coords[0];
  const lastCoord = coords[coords.length - 1];
  if (firstCoord[0] !== lastCoord[0] || firstCoord[1] !== lastCoord[1]) {
      return false;
  }

  // If the above checks pass, the coordinates represent a polygon
  return true;
}
// Helper function to convert coordinates to GeoJSON feature
function coordinatesToGeoJsonFeature(polygon: IFlattenedIOPolygon): GeoJSON.Feature {
  const data = polygon.coordinates;
  const isPolygon = isPolygonFunc(data);
  
  // reverse the order of the coordinates
  const precoordinates = polygon.coordinates.map(coord => [coord[1], coord[0]]);

  const coordinates = isPolygon ? [precoordinates] : precoordinates;

  const type =  isPolygon ? "Polygon" : "LineString";
  
  const geoJsonFeature: GeoJSON.Feature = {
      type: "Feature",
      geometry: {
          type: type,
          // @ts-ignore
          coordinates: coordinates
      },
      properties: {
          optionNumber: polygon.optionNumber
      }
  };
  
  return geoJsonFeature;
}

type IFlattenedIOPolygon = 
  {
    coordinates: number[][];
    optionNumber: string;
  }

// Main function to create GeoJSON FeatureCollection
export function coordinatesToGeoJsonFeatureCollection(polygons: IOPolygon[]): GeoJSON.FeatureCollection {

  // filter nulls
  const filtered = polygons.filter(polygon => polygon.coordinates !== null);

  const flattenedPolygons: IFlattenedIOPolygon[] = []
  // Assign each polygon a unique id
  for (let i = 0; i < filtered.length; i++) {
      const polygon = filtered[i];
      const optionNumber = polygon.optionNumber;
      const coordinates = polygon.coordinates;
      if (coordinates === null) {
          continue;
      }
      for (let j = 0; j < coordinates.length; j++) {
          const data = coordinates[j];
          flattenedPolygons.push({
              coordinates: data,
              optionNumber: `${optionNumber}-${j}`
          });
      }
  }
  

  const features: GeoJSON.Feature[] = flattenedPolygons.map(polygon => coordinatesToGeoJsonFeature(polygon));

  const geoJsonFeatureCollection: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: features
  };
  
  return geoJsonFeatureCollection;
}


import { Get } from '@microsoft/mgt-react';

export const HelperLogForSharepointLibId = () => {
  return (
    <Get
      resource="/sites/shearwatergeonorway.sharepoint.com:/sites/SWWebmap"
      dataChange={(e) => {
        const newE = e as any;
        const eValue = newE.detail.response as any;
        console.log('=====SHAREPOINT LIB DETAILS =====\n', eValue);
      }}
      maxPages={0}
    ></Get>
  );
};

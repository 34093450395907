import L from 'leaflet';
import { FeatureLayer } from 'react-esri-leaflet';
import { Pane } from 'react-leaflet';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEdinToken } from '../../hooks/useEdinToken';
import { layerState } from '../../state/map';
import { popupDataState } from '../../state/popup';
import { SWLayersControlOverlay } from '../SWLayersControl';

const defaultStyle = {
  color: 'lightblue',
  weight: 3,
  opacity: 1,
  fillOpacity: 0.5,
  fillColor: 'none',
};

const EdinLayers = [
  {
    name: 'Seismic Polygons 3D (Zoom In to See)',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/seismic_2_0/MapServer/7',
    minZoom: 6,
    style: defaultStyle,
    selectedStyle: { ...defaultStyle, color: 'red' },
  },
  {
    name: 'Seismic Lines 2D (Zoom In to See)',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/seismic_2_0/MapServer/6',
    minZoom: 6,
    style: defaultStyle,
    selectedStyle: { ...defaultStyle, color: 'red' },
  },
  {
    name: 'Valid Licensed Blocks (Zoom In to See)',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/validlicensedblock_3_0/MapServer/3',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray', fillColor: 'lightyellow' },
    selectedStyle: { ...defaultStyle, color: 'black', fillColor: 'yellow' },
  },
  {
    name: 'License Grid 1st Order (Zoom in to see)',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/culture/grid/MapServer/7',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Bidding Blocks (Zoom In to See)',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/availablevalidblock_3_0/MapServer/3',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray', fillColor: 'lightGreen' },
    selectedStyle: { ...defaultStyle, color: 'black', fillColor: 'green' },
  },
  {
    name: 'Fields',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/field_3_0/MapServer/11',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray', fillColor: 'lightBlue' },
    selectedStyle: { ...defaultStyle, color: 'black', fillColor: 'blue' },
  },
  {
    name: 'Platforms',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/field_3_0/MapServer/7',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Prospects',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/field_3_0/MapServer/8',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Wells',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/well_2_0/MapServer/2',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Planned Surveys',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/plannedactivity/MapServer/6',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Planned Exploration Drilling',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/plannedactivity/MapServer/7',
    minZoom: 6,
    style: { ...defaultStyle, color: 'lightGray' },
    selectedStyle: { ...defaultStyle, color: 'black' },
  },
  {
    name: 'Planned License Rounds',
    url: 'https://mapsecurity.ems.ihsenergy.com/wss/service/ags-relay/intl_100/agstoken/arcgis/rest/services/ep/plannedactivity/MapServer/8',
    minZoom: 3,
    style: { ...defaultStyle, color: 'lightGray', fillColor: 'lightBlue' },
    selectedStyle: { ...defaultStyle, color: 'black', fillColor: 'blue' },
  },
];

export const EdinBlocks = () => {
  const { edinToken } = useEdinToken();

  return (
    <div>
      {edinToken && (
        <>
          {EdinLayers.map((layer) => (
            <EdinPerLayer key={layer.name} {...layer} />
          ))}
        </>
      )}
    </div>
  );
};

type IStyleType = {
  color: string;
  weight: number;
  opacity: number;
  fillOpacity: number;
  fillColor: string;
};

type EdinPerLayerProps = {
  name: string;
  url: string;
  minZoom?: number;
  maxZoom?: number;
  style: IStyleType;
  selectedStyle: IStyleType;
};

const EdinPerLayer: React.FC<EdinPerLayerProps> = ({ name, url, minZoom, maxZoom, style, selectedStyle }) => {
  const setPopupData = useSetRecoilState(popupDataState);
  const { edinToken } = useEdinToken();
  const [layerStateValue, setLayerStateValue] = useRecoilState(layerState(name));
  const { edinFilter } = layerStateValue;
  return (
    <SWLayersControlOverlay name={name} type="EDIN">
      <Pane name={name} style={{ zIndex: 3 }}>
        <FeatureLayer
          //@ts-ignore
          url={url}
          where={edinFilter ?? '1=1'}
          minZoom={minZoom}
          maxZoom={maxZoom}
          // This is to simplify the polygons on zooming out and helps in performance
          simplifyFactor={0.5}
          eventHandlers={{
            loading: () => {
              setLayerStateValue((prev) => ({ ...prev, isLoading: true }));
            },
            load: () => {
              setLayerStateValue((prev) => ({ ...prev, isLoading: false }));
            },
          }}
          style={() => style}
          onEachFeature={(feature: GeoJSON.Feature, layer: { on: (arg0: string, arg1: (e: any) => void) => void }) => {
            let isSelected = false;
            if (feature.properties) {
              layer.on('click', (e) => {
                isSelected = !isSelected;
                if (isSelected) {
                  e.originalEvent.stopPropagation();
                  setPopupData({
                    properties: feature.properties,
                    latlng: e.latlng,
                  });
                  e.target.setStyle(selectedStyle);
                } else {
                  e.target.setStyle(style);
                }
              });
            }

            layer.on('mouseover', (e) => {
              e.target.setStyle(selectedStyle);

              if (isSelected) return;

              e.target.bringToFront();

              if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                e.target.bringToFront();
              }
            });
            layer.on('mouseout', (e) => {
              if (isSelected) return;
              e.target.setStyle(style);
            });
            layer.on('popupclose', () => {
              console.log('popupclose');
              isSelected = false;
            });
          }}
          token={edinToken?.token}
        />
      </Pane>
    </SWLayersControlOverlay>
  );
};

import { useEffect, useRef, useState } from 'react';

import { open } from 'shapefile';
import zip from 'jszip';
import { Libraries } from '../constants/SPLibraryIds';
import { Providers } from '@microsoft/mgt-element';
import { useIsSignedIn } from '../hooks/useIsSignedIn';
import { Feature } from 'geojson';
import { Pane, GeoJSON } from 'react-leaflet';
import proj4 from 'proj4';
import { GeoJsonObject } from 'geojson';
import { SWLayersControlOverlay } from './SWLayersControl';

type ShapefileCompType = {
  filepath: string;
  title: string;
  colour: string;
};

export const ShapefileComp: React.FC<ShapefileCompType> = ({ filepath, title, colour }) => {
  const geojsonRef = useRef<Array<Feature>>([]);

  const [geojson, setGeojson] = useState<GeoJsonObject | null>(null);

  const resource = `sites/${Libraries['GeoSupportShearwater'].id}/drive/root:${filepath}`;

  const client = Providers.client;

  const signedIn = useIsSignedIn();

  useEffect(() => {
    if (signedIn) {
      client
        .api(resource)
        .get()
        .then((res) => {
          // console.log('res', res);
          return res;
        })
        .then((res) => {
          const dataUrl = res['@microsoft.graph.downloadUrl'];
          // console.log('dataUrl', dataUrl);
          return dataUrl;
        })
        .then((dataUrl) => {
          return fetch(dataUrl);
        })
        .then((res) => {
          // console.log('res', res);
          return res.arrayBuffer();
        })
        .then((data) => {
          // console.log('data', data);
          return data;
        })
        .then(async (data) => {
          await zip
            .loadAsync(data)
            .then(async (zip) => {
              // console.log('zip', zip);
              const promise = new Promise<{ shapefileData: Uint8Array; dbfData: Uint8Array; prjData: Uint8Array }>(
                (resolve, reject) => {
                  let shapefileData: Uint8Array, dbfData: Uint8Array, prjData: Uint8Array;
                  Object.keys(zip.files).forEach(function (filename) {
                    zip.files[filename].async('uint8array').then(function (fileData) {
                      // console.log({ filename, fileData });
                      if (filename.endsWith('.shp')) {
                        shapefileData = fileData;
                      } else if (filename.endsWith('.dbf')) {
                        dbfData = fileData;
                      } else if (filename.endsWith('.prj')) {
                        prjData = fileData;
                      }

                      if (shapefileData && dbfData && prjData) {
                        resolve({ shapefileData, dbfData, prjData });
                      }
                    });
                  });
                }
              );
              const { shapefileData, dbfData, prjData } = await promise;
              return { shapefileData, dbfData, prjData };
            })
            .then(({ shapefileData, dbfData, prjData }) => {
              // console.log({ shapefileData, dbfData });

              // convert prjData to string
              const decoder = new TextDecoder('utf-8');
              const prjString = decoder.decode(prjData);
              // console.log({ prjString });

              // projection definition
              const converter = proj4(prjString, 'EPSG:4326');

              if (!shapefileData || !dbfData) {
                throw new Error('Missing data');
              }
              // console.log({ shapefileData, dbfData });
              open(shapefileData, dbfData).then((source) =>
                // @ts-ignore
                source.read().then(function log(result) {
                  if (result.done) {
                    // console.log('done');
                    setGeojson({
                      type: 'FeatureCollection',
                      features: geojsonRef.current,
                    } as GeoJsonObject);
                    return;
                  }
                  // console.log(result.value);
                  const feature = result.value;

                  // convert coordinates
                  if (feature.geometry.type === 'Polygon') {
                    feature.geometry.coordinates = feature.geometry.coordinates.map((coords) => {
                      return coords.map((coord) => {
                        return converter.forward(coord);
                      });
                    });
                  }

                  geojsonRef.current.push(feature);
                  return source.read().then(log);
                })
              );
            });
        })

        .catch((err) => console.log(err));
    }
  }, []);

  if (!geojson) {
    return null;
  }

  // console.log({ geojson });

  return (
    <SWLayersControlOverlay name={title}>
      <Pane name={title} style={{ zIndex: 1 }}>
        <GeoJSON
          data={geojson}
          style={{ color: colour, weight: 1, fillOpacity: 0.1 }}
          onEachFeature={(feature, layer) => {
            // bind all properties to popup
            const popupContent = Object.keys(feature.properties)
              .map((key) => {
                return `<strong>${key}</strong>: ${feature.properties[key]}`;
              })
              .join('<br />');
            layer.bindPopup(popupContent);
          }}
        />
      </Pane>
    </SWLayersControlOverlay>
  );
};

import { Box, Button, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { Login } from '@microsoft/mgt-react';

export const Navbar = () => {
  return (
    <Flex justifyContent="space-between">
      <Stack p={1} direction="row" spacing={4} alignItems="baseline">
        <Heading as="h1" size="xl" p={2}>
          SW Webmap
        </Heading>
        <Text fontSize="md" p={2}>
          First Port of Call for GIS Data
        </Text>
      </Stack>
      <Stack direction="row" spacing={4} mt={4} alignItems="baseline">
        <Box>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              window.open(
                'https://shearwatergeonorway.sharepoint.com/:b:/r/sites/SWWebmap/Shared%20Documents/Documentation/About%20SW%20Webmap.pdf?csf=1&web=1&e=tobu3e',
                '_blank'
              );
            }}
          >
            About
          </Button>
        </Box>
        <Box>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => {
              window.open(
                'https://apps.powerapps.com/play/e/default-9209ee24-f117-43b3-85d9-86cc9263c8a4/a/c390f2be-4da2-4522-909d-dabee267c07c?tenantId=9209ee24-f117-43b3-85d9-86cc9263c8a4',
                '_blank'
              );
            }}
          >
            Link to Feedback Tracker & Wishlist
          </Button>
        </Box>
        <Box>
          <Login />
        </Box>
      </Stack>
    </Flex>
  );
};

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Suspense, useRef, useState } from 'react';
import {
  clients as validClients,
  surveyStatus as validSurveyStatus,
  vessels as validVessels,
  countries as validCountries,
  companies as validCompanies,
  surveyTypes as validTypes,
  productionYear as validYears,
} from '../../data/filters';

import { Select } from 'chakra-react-select';
import { useRecoilState, useRecoilValue } from 'recoil';
import { seisintelLevel0Data, ISeisintelLevel0Data, seisintelLevel1Data } from '../../state/seisintelData';
import { SeisintelLevel0Table } from './SeisintelLevel0Table';
import { seisintelLayersState } from '../../state/map';
import { LayerMenuItem } from '../SWLayersControl';
import { SeisintelVesselsTable } from './SeisintelVesselsTable';

export const SeisintelFilters: React.FC = () => {
  // build a form to input the filters
  // build a button to submit the filters
  // build a button to clear the filters

  const [filters, setFilters] = useState({
    surveyStatus: { label: 'In Progress', value: 'In Progress' },
    vessel: { label: '', value: '' },
    surveyName: { label: '', value: '' },
    client: { label: '', value: '' },
    type: { label: '', value: '' },
    surveyCompany: { label: '', value: '' },
    country: { label: '', value: '' },
    year: { label: '', value: '' },
  });

  const [isSearching, setIsSearching] = useState(false);

  const [accordionIndex, setAccordionIndex] = useState(0);

  const [level0Data, setLevel0Data] = useRecoilState(seisintelLevel0Data);
  const [, setLevel1Data] = useRecoilState(seisintelLevel1Data);

  const ref = useRef<HTMLFormElement>(null);

  type FilterKeysType = keyof typeof filters;

  const onChange = (key: FilterKeysType) => (item: { label: string; value: string }) => {
    setFilters((prev) => ({ ...prev, [key]: item }));
  };

  const REQUEST_PARAMS = (level: number) => ({
    outputFormat: 'application/json',
    count: '500',
    request: 'GetFeature',
    service: 'WFS',
    typeNames: level === 0 ? 'seisintel:Level_0' : 'seisintel:Level_1',
    version: '2.0.0',
    startIndex: '0',
    // TODO check by sort by is not working
    // sortBy: 'prod_end_date+D',
  });

  const fetchSeisintelData = async (level: number) => {
    const formData = new FormData(ref.current ?? undefined);

    const data = Object.fromEntries(formData);

    const filtersWithValues = Object.entries(filters).reduce((acc, [key, item]) => {
      if (item.value !== '') {
        acc[key as FilterKeysType] = item;
      }
      return acc;
    }, {} as typeof filters);

    const cql = Object.entries(filtersWithValues).reduce((acc, [key, item]) => {
      if (key === 'surveyStatus' && item.value !== 'Any') {
        acc.push(`survey_complete='${item.value === 'Completed' ? 'Yes' : 'In Progress'}'`);
      } else if (key === 'vessel') {
        acc.push(`vessel='${item.value}'`);
      } else if (key === 'surveyName') {
        acc.push(`survey_name='${item.value}'`);
      } else if (key === 'client') {
        acc.push(`client='${item.value}'`);
      } else if (key === 'type') {
        acc.push(`type='${item.value}'`);
      } else if (key === 'surveyCompany') {
        acc.push(`company='${item.value}'`);
      } else if (key === 'country') {
        acc.push(`country='${item.value}'`);
      } else if (key === 'year') {
        acc.push(`prod_start_date DURING ${item.value}-01-01T00:00:00.000Z/${item.value}-12-31T23:59:59.999Z`);
      }
      return acc;
    }, [] as string[]);

    //combine cql with REQUEST_PARAMS
    const combined = { ...REQUEST_PARAMS(level), cql_filter: cql.join(' AND ') };

    // remove any empty values
    Object.keys(combined).forEach((key) => {
      if (combined[key as keyof typeof combined] === '') {
        delete combined[key as keyof typeof combined];
      }
    });

    const params = new URLSearchParams(combined);

    const fetchUrl = `${url}?${params.toString()}`;

    console.log({ data });

    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        authorization: `Basic ${process.env.REACT_APP_WEBMAP_ACCESS_TOKEN}`,
      },
    });

    const resJson = await response.json();

    return resJson as ISeisintelLevel0Data;
  };

  const fetchSeisintelDataWithSurveyIds = async (surveyIds: string[], level = 1) => {
    const cql = surveyIds.map((id) => `survey_id='${id}'`).join(' OR ');

    const combined = { ...REQUEST_PARAMS(level), cql_filter: cql };

    // remove any empty values
    Object.keys(combined).forEach((key) => {
      if (combined[key as keyof typeof combined] === '') {
        delete combined[key as keyof typeof combined];
      }
    });

    const params = new URLSearchParams(combined);

    const fetchUrl = `${url}?${params.toString()}`;

    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        authorization: `Basic ${process.env.REACT_APP_WEBMAP_ACCESS_TOKEN}`,
      },
    });

    const resJson = await response.json();

    return resJson as ISeisintelLevel0Data;
  };

  const url = process.env.REACT_APP_BACKEND_URL
    ? `${process.env.REACT_APP_BACKEND_URL}/seisintelwfs`
    : 'https://sales-webmap-backend.azurewebsites.net/seisintelwfs';

  const handleSearch = async (e: { preventDefault: () => void }, add = false) => {
    console.log('searching');
    setIsSearching(true);
    if (!add) {
      setLevel0Data(null);
      setLevel1Data(null);
    }
    e.preventDefault();

    // level 1 data does not have filters for prod_start_date and prod_end_date
    // we first fetch level 0 data and get the level 1 data with survey_id from level 0 data
    // const [level0Data, level1Data] = await Promise.all([fetchSeisintelData(0), fetchSeisintelData(1)]);
    const level0Data = await fetchSeisintelData(0);

    const surveyIds = level0Data.features.map((feature) => feature.properties.survey_id);

    // break the surveyIds into chunks of 50
    const surveyIdsChunks = surveyIds.reduce((acc, id, index) => {
      const chunkIndex = Math.floor(index / 50);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }

      acc[chunkIndex].push(id);

      return acc;
    }, [] as string[][]);

    const level1DataChunked = await Promise.all(surveyIdsChunks.map((chunk) => fetchSeisintelDataWithSurveyIds(chunk)));

    const level1Data = level1DataChunked.reduce(
      (acc, data) => {
        acc.features.push(...data.features);
        return acc;
      },
      { ...level0Data, features: [] as ISeisintelLevel0Data['features'] }
    );
    // const level1Data = await fetchSeisintelDataWithSurveyIds(surveyIds);

    setIsSearching(false);

    setAccordionIndex(1);

    if (add) {
      setLevel0Data((prev) => {
        if (prev) {
          return {
            ...prev,
            features: [...prev.features, ...level0Data.features],
          };
        }
        return level0Data;
      });
      setLevel1Data((prev) => {
        if (prev) {
          return {
            ...prev,
            features: [...prev.features, ...level1Data.features],
          };
        }
        return level1Data;
      });
      return;
    }
    setLevel0Data(level0Data);
    setLevel1Data(level1Data);
  };

  const handleReset = () => {
    setLevel0Data(null);
    setLevel1Data(null);
    setFilters({
      surveyStatus: { label: 'In Progress', value: 'In Progress' },
      vessel: { label: '', value: '' },
      surveyName: { label: '', value: '' },
      client: { label: '', value: '' },
      type: { label: '', value: '' },
      surveyCompany: { label: '', value: '' },
      country: { label: '', value: '' },
      year: { label: '', value: '' },
    });
  };

  return (
    <Box px={4} h={'auto'} maxH={'90vh'} background={'white'} w={'auto'} minW={620} maxW={900} overflow="auto">
      <SeisintelLayersMenu />
      <Box mt={14}>
        <Text pt={6} pb={2}>
          * Use filters below to search for surveys
        </Text>
        <Accordion allowToggle index={accordionIndex} backgroundColor="gray.50">
          <AccordionItem>
            <AccordionButton onClick={() => setAccordionIndex(accordionIndex === 0 ? -1 : 0)}>
              <Box flex="1" textAlign="left">
                Seisintel Filters
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} zIndex={1000}>
              <form ref={ref} onSubmit={handleSearch} onReset={handleReset}>
                {/* Two columns
                 */}
                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <AutoComplete
                    id="surveyStatus"
                    label="Survey Status"
                    value={filters.surveyStatus}
                    onChange={(value) => onChange('surveyStatus')(value)}
                    placeholder="Select survey status"
                    items={validSurveyStatus}
                  />

                  <AutoComplete
                    id="vessel"
                    label="Vessel"
                    value={filters.vessel}
                    onChange={(value) => onChange('vessel')(value)}
                    placeholder="Select vessel"
                    items={validVessels.filter((vessel) => !!vessel) as string[]}
                  />
                  <FormControl id="surveyName">
                    <FormLabel>Survey Name or ID</FormLabel>
                    <Input
                      value={filters.surveyName.value}
                      onChange={(event) =>
                        onChange('surveyName')({ label: event.target.value, value: event.target.value })
                      }
                      placeholder="Enter survey name or ID"
                    />
                  </FormControl>

                  <AutoComplete
                    id="client"
                    label="Client"
                    value={filters.client}
                    onChange={(value) => onChange('client')(value)}
                    placeholder="Select client"
                    items={validClients.filter((client) => !!client) as string[]}
                  />

                  <AutoComplete
                    id="type"
                    label="Survey Type"
                    value={filters.type}
                    onChange={(value) => onChange('type')(value)}
                    placeholder="Select type"
                    items={validTypes}
                  />

                  <AutoComplete
                    id="surveyCompany"
                    label="Survey Company"
                    value={filters.surveyCompany}
                    onChange={(value) => onChange('surveyCompany')(value)}
                    placeholder="Select survey company"
                    items={validCompanies.filter((company) => !!company) as string[]}
                  />

                  <AutoComplete
                    id="country"
                    label="Country"
                    value={filters.country}
                    onChange={(value) => onChange('country')(value)}
                    placeholder="Select country"
                    items={validCountries}
                  />

                  <AutoComplete
                    id="year"
                    label="Year"
                    value={filters.year}
                    onChange={(value) => onChange('year')(value)}
                    placeholder="Select year"
                    items={validYears.map((year) => year.toString())}
                  />
                </Grid>
                <ButtonGroup mt={4} display="flex" justifyContent="space-between">
                  <Button type="submit" isLoading={isSearching}>
                    Search
                  </Button>
                  <Button isLoading={isSearching} onClick={(e) => handleSearch(e, true)}>
                    Add
                  </Button>
                  <Button type="reset">Clear</Button>
                </ButtonGroup>
              </form>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton onClick={() => setAccordionIndex(accordionIndex === 1 ? -1 : 1)}>
              <Box flex="1" textAlign="left">
                Seisintel Results ({level0Data?.features.length ?? 0})
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <SeisintelLevel0Table />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton onClick={() => setAccordionIndex(accordionIndex === 2 ? -1 : 2)}>
              <Box flex="1" textAlign="left">
                Seisintel vessels
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Suspense fallback={<div>Loading...</div>}>
                <SeisintelVesselsTable />
              </Suspense>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

interface AutocompleteProps {
  id: string;
  label: string;
  value: { label: string; value: string };
  onChange: (item: { label: string; value: string }) => void;
  placeholder: string;
  items: string[];
  defaultValue?: string;
}

const AutoComplete: React.FC<AutocompleteProps> = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  items,
  defaultValue,
}) => {
  // add '' to the list of items to allow for clearing the filter with '' the first value

  const itemsWithLabels = items.map((item) => ({ label: item, value: item }));

  const newItems = [...itemsWithLabels, { label: 'Any', value: '' }];

  const newItemsSorted = newItems.sort((a, b) => {
    if (a.label === 'Any') {
      return -1;
    }
    if (b.label === 'Any') {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <Select
        value={value}
        defaultInputValue={defaultValue}
        onChange={(newItemsSorted) => {
          onChange(newItemsSorted ?? { label: 'Any', value: '' });
        }}
        name={label}
        placeholder={placeholder}
        options={newItemsSorted.map((item) => ({
          label: item.label,
          value: item.value,
        }))}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </FormControl>
  );
};

const SeisintelLayersMenu = () => {
  const seisintelLayers = useRecoilValue(seisintelLayersState);
  return (
    <Box
      position="fixed"
      backgroundColor="white"
      borderRadius="md"
      borderWidth={1}
      borderColor="gray.200"
      mt={-3}
      zIndex={1000}
      px={4}
      py={2}
    >
      <Heading size="sm" mb={2}>
        Seisintel Layers
      </Heading>
      <Stack direction="row" spacing={4}>
        {seisintelLayers.map((layer) => (
          <LayerMenuItem key={layer.name} layerId={layer.name} />
        ))}
      </Stack>
    </Box>
  );
};

import { IProject } from "../../components/Geosupport/GetProjects";
import { Libraries } from "../../constants/SPLibraryIds";
import { IListItemWithKmlUrl } from "../../schemas/IListItemWithKmlUrl";
import { getPolygonArrayFromKMLUrl } from "../getPolygonArrayFromKML";

export interface IOPolygon {
  optionNumber: string;
  coordinates: [number, number][][] | null;
}

export interface IFileData {
  name: string;
  '@microsoft.graph.downloadUrl': string;
  size: number;
  webUrl: string;
}

export const updatePolygons = async (option: IListItemWithKmlUrl, handleSetPolygon: any, data: IFileData | null ) => {
  const stringfiedCoordinates = sessionStorage.getItem(option.url);

  if (!data || !data['@microsoft.graph.downloadUrl']) {
    handleSetPolygon(null);
    return;
  }

  if (stringfiedCoordinates) {
    const parsedCoordinates = JSON.parse(stringfiedCoordinates);
    handleSetPolygon(parsedCoordinates);
  } else {
    const polygonArray = await getPolygonArrayFromKMLUrl(data['@microsoft.graph.downloadUrl']);

    const strigifiedCoordinates = JSON.stringify(polygonArray);
    if (polygonArray) {
      sessionStorage.setItem(option.url, strigifiedCoordinates);
      handleSetPolygon(JSON.parse(strigifiedCoordinates) as [number, number][][]);
    }
  }
};

export const getOptionResourcePath = (option: IListItemWithKmlUrl) => {
  type ISite = keyof typeof Libraries;
  return `sites/${Libraries[option.site as ISite].id}/drive/root:/${option.path}`;
};

export const getSurveyResourcePath = (survey: IListItemWithKmlUrl) => {
  type ISite = keyof typeof Libraries;
  return `sites/${Libraries[survey.site as ISite].id}/drive/root:/${survey.path}`;
};

export const getResourcePath = (item: IListItemWithKmlUrl, type: 'survey' | 'option' | 'project') => {
  if (type === 'option') {
    return getOptionResourcePath(item);
  } else if (type === 'survey') {
    return getSurveyResourcePath(item);
  }
  else if (type === 'project') {
    return item.path;
  }

  return '';
};


export const getProjectKmlFolderPath = (project: IProject) => {
  type ISite = keyof typeof Libraries;
  return `sites/${Libraries[project.site as ISite].id}/drive/root:/${project?.kmlFolderPath}:/children`;
}
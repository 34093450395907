import { Get } from '@microsoft/mgt-react';
import { IListItemWithKmlUrl } from '../../schemas/IListItemWithKmlUrl';

export const GetOptions: React.FC<{
  handleLoadingFinished: () => void;
  handleOptionsUpdate: (options: IListItemWithKmlUrl[]) => void;
}> = ({ handleLoadingFinished, handleOptionsUpdate }) => (
  <Get
    resource="sites/shearwatergeonorway.sharepoint.com,657af727-6515-4f95-8444-c6b5ce673ba1,cc0e5053-dbf4-4278-992a-14a02f003aa3/lists/8894ac34-bc64-4701-8c1d-9de08acfed6e/items?expand=fields(select=Title,Option_Number,KML)"
    dataChange={(e) => {
      handleLoadingFinished();
      const newE = e as any;
      const eValue = newE.detail.response.value as any[];
      let newOptions: IListItemWithKmlUrl[] = [];
      eValue.forEach((o) => {
        const { Title: title, Option_Number: itemNumber, KML: kml } = o.fields;
        const { Url: url } = kml || {};
        // removed check on existing url as we are caching the kmls anyway.
        // const existingUrl = url && newOptions.find((o) => o.url === url);

        const split1 = url && url.split('/sites/');
        const split2 = split1 && split1.length > 1 && split1[1].replace('%20', ' ').split('/Shared Documents/');

        if (split2 && split2.length > 1) {
          const [site, path] = split2;
          const option = {
            title,
            itemNumber,
            site,
            path,
            url,
          };
          newOptions.push(option);
        }
      });
      handleOptionsUpdate(newOptions);
    }}
    maxPages={0}
  />
);

import L from 'leaflet';
import { Suspense } from 'react';
import { Pane, GeoJSON } from 'react-leaflet';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { popupDataState } from '../../state/popup';
import { seisintelLevel0Data, seisintelLevel1Data } from '../../state/seisintelData';
import { SWLayersControlOverlay } from '../SWLayersControl';

export const Seisintel: React.FC = () => {
  const seisintelLevel0 = useRecoilValue(seisintelLevel0Data);
  const seisintelLevel1 = useRecoilValue(seisintelLevel1Data);

  if (!seisintelLevel0 || !seisintelLevel1) {
    return null;
  }

  return (
    <>
      <SeisintelPerLayer
        key="Operational Area (Seisintel_level0)"
        name="Operational Area (Seisintel_level0)"
        color="red"
        data={seisintelLevel0}
      />
      <SeisintelPerLayer
        key="Fullfold Area (Seisintel_level1)"
        name="Fullfold Area (Seisintel_level1)"
        color="orange"
        data={seisintelLevel1}
      />
    </>
  );
};

type SeisintelPerLayerProps = {
  name: string;
  data: GeoJSON.GeoJsonObject;
  color: string;
};

const SeisintelPerLayer: React.FC<SeisintelPerLayerProps> = ({ name, data, color }) => {
  const setPopupData = useSetRecoilState(popupDataState);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SWLayersControlOverlay checked name={name} type="Seisintel">
        <Pane name={name} style={{ zIndex: 5 }}>
          <GeoJSON
            data={data}
            style={() => ({
              color,
              weight: 3,
              opacity: 1,
              fillOpacity: 0.5,
              fillColor: 'none',
            })}
            onEachFeature={(feature, layer) => {
              if (feature.properties) {
                layer.on('click', (e: any) => {
                  console.log({ e });
                  e.originalEvent.stopPropagation();
                  setPopupData({
                    properties: feature.properties,
                    latlng: e.latlng,
                  });
                });
              }

              layer.on('mouseover', (e) => {
                e.target.setStyle({
                  weight: 6,
                  color,
                  fillOpacity: 0.7,
                  fillColor: 'none',
                });

                e.target.bringToFront();

                if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                  e.target.bringToFront();
                }
              });
              layer.on('mouseout', (e) => {
                e.target.setStyle({
                  weight: 3,
                  color,
                  fillOpacity: 0.5,
                  fillColor: 'none',
                });
              });
            }}
          />
        </Pane>
      </SWLayersControlOverlay>
    </Suspense>
  );
};
